@font-face {
  font-family: 'Lexend Deca';
  src: local('Lexend Deca'), url('./LexendDeca-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend Deca';
  src: local('Lexend Deca'), url('./LexendDeca-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend Deca';
  src: local('Lexend Deca'), url('./LexendDeca-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend Deca';
  src: local('Lexend Deca'), url('./LexendDeca-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend Deca';
  src: local('Lexend Deca'), url('./LexendDeca-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend Deca';
  src: local('Lexend Deca'), url('./LexendDeca-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend Deca';
  src: local('Lexend Deca'), url('./LexendDeca-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend Deca';
  src: local('Lexend Deca'), url('./LexendDeca-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend Deca';
  src: local('Lexend Deca'), url('./LexendDeca-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
