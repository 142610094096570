@font-face {
  font-family: 'DM Sans';
  src: local('DM Sans'), url('./DMSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans';
  src: local('DM Sans'), url('./DMSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'DM Sans';
  src: local('DM Sans'), url('./DMSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans';
  src: local('DM Sans'), url('./DMSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'DM Sans';
  src: local('DM Sans'), url('./DMSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans';
  src: local('DM Sans'), url('./DMSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
