@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./Roboto-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
