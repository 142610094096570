@font-face {
  font-family: 'fontello';
  src: url('./fonts/fontello.eot?76754549');
  src: url('./fonts/fontello.eot?76754549#iefix') format('embedded-opentype'),
    url('./fonts/fontello.woff2?76754549') format('woff2'),
    url('./fonts/fontello.woff?76754549') format('woff'),
    url('./fonts/fontello.ttf?76754549') format('truetype'),
    url('./fonts/fontello.svg?76754549#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?76754549#fontello') format('svg');
  }
}
*/

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  text-align: center;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-feather-alert-octagon:before {
  content: '\21';
} /* '!' */
.icon-feather-alert-circle:before {
  content: '\22';
} /* '&quot;' */
.icon-feather-activity:before {
  content: '\23';
} /* '#' */
.icon-feather-alert-triangle:before {
  content: '\24';
} /* '$' */
.icon-feather-align-center:before {
  content: '\25';
} /* '%' */
.icon-feather-airplay:before {
  content: '\26';
} /* '&amp;' */
.icon-feather-align-justify:before {
  content: '\27';
} /* ''' */
.icon-feather-align-left:before {
  content: '\28';
} /* '(' */
.icon-feather-align-right:before {
  content: '\29';
} /* ')' */
.icon-feather-arrow-down-left:before {
  content: '\2a';
} /* '*' */
.icon-feather-arrow-down-right:before {
  content: '\2b';
} /* '+' */
.icon-feather-anchor:before {
  content: '\2c';
} /* ',' */
.icon-feather-aperture:before {
  content: '\2d';
} /* '-' */
.icon-feather-arrow-left:before {
  content: '\2e';
} /* '.' */
.icon-feather-arrow-right:before {
  content: '\2f';
} /* '/' */
.icon-feather-arrow-down:before {
  content: '\30';
} /* '0' */
.icon-feather-arrow-up-left:before {
  content: '\31';
} /* '1' */
.icon-feather-arrow-up-right:before {
  content: '\32';
} /* '2' */
.icon-feather-arrow-up:before {
  content: '\33';
} /* '3' */
.icon-feather-award:before {
  content: '\34';
} /* '4' */
.icon-feather-bar-chart:before {
  content: '\35';
} /* '5' */
.icon-feather-at-sign:before {
  content: '\36';
} /* '6' */
.icon-feather-bar-chart-2:before {
  content: '\37';
} /* '7' */
.icon-feather-battery-charging:before {
  content: '\38';
} /* '8' */
.icon-feather-bell-off:before {
  content: '\39';
} /* '9' */
.icon-feather-battery:before {
  content: '\3a';
} /* ':' */
.icon-feather-bluetooth:before {
  content: '\3b';
} /* ';' */
.icon-feather-bell:before {
  content: '\3c';
} /* '&lt;' */
.icon-feather-book:before {
  content: '\3d';
} /* '=' */
.icon-feather-briefcase:before {
  content: '\3e';
} /* '&gt;' */
.icon-feather-camera-off:before {
  content: '\3f';
} /* '?' */
.icon-feather-calendar:before {
  content: '\40';
} /* '@' */
.icon-feather-bookmark:before {
  content: '\41';
} /* 'A' */
.icon-feather-box:before {
  content: '\42';
} /* 'B' */
.icon-feather-camera:before {
  content: '\43';
} /* 'C' */
.icon-feather-check-circle:before {
  content: '\44';
} /* 'D' */
.icon-feather-check:before {
  content: '\45';
} /* 'E' */
.icon-feather-check-square:before {
  content: '\46';
} /* 'F' */
.icon-feather-cast:before {
  content: '\47';
} /* 'G' */
.icon-feather-chevron-down:before {
  content: '\48';
} /* 'H' */
.icon-feather-chevron-left:before {
  content: '\49';
} /* 'I' */
.icon-feather-chevron-right:before {
  content: '\4a';
} /* 'J' */
.icon-feather-chevron-up:before {
  content: '\4b';
} /* 'K' */
.icon-feather-chevrons-down:before {
  content: '\4c';
} /* 'L' */
.icon-feather-chevrons-right:before {
  content: '\4d';
} /* 'M' */
.icon-feather-chevrons-up:before {
  content: '\4e';
} /* 'N' */
.icon-feather-chevrons-left:before {
  content: '\4f';
} /* 'O' */
.icon-feather-circle:before {
  content: '\50';
} /* 'P' */
.icon-feather-clipboard:before {
  content: '\51';
} /* 'Q' */
.icon-feather-chrome:before {
  content: '\52';
} /* 'R' */
.icon-feather-clock:before {
  content: '\53';
} /* 'S' */
.icon-feather-cloud-lightning:before {
  content: '\54';
} /* 'T' */
.icon-feather-cloud-drizzle:before {
  content: '\55';
} /* 'U' */
.icon-feather-cloud-rain:before {
  content: '\56';
} /* 'V' */
.icon-feather-cloud-off:before {
  content: '\57';
} /* 'W' */
.icon-feather-codepen:before {
  content: '\58';
} /* 'X' */
.icon-feather-cloud-snow:before {
  content: '\59';
} /* 'Y' */
.icon-feather-compass:before {
  content: '\5a';
} /* 'Z' */
.icon-feather-copy:before {
  content: '\5b';
} /* '[' */
.icon-feather-corner-down-right:before {
  content: '\5c';
} /* '\' */
.icon-feather-corner-down-left:before {
  content: '\5d';
} /* ']' */
.icon-feather-corner-left-down:before {
  content: '\5e';
} /* '^' */
.icon-feather-corner-left-up:before {
  content: '\5f';
} /* '_' */
.icon-feather-corner-up-left:before {
  content: '\60';
} /* '`' */
.icon-feather-corner-up-right:before {
  content: '\61';
} /* 'a' */
.icon-feather-corner-right-down:before {
  content: '\62';
} /* 'b' */
.icon-feather-corner-right-up:before {
  content: '\63';
} /* 'c' */
.icon-feather-cpu:before {
  content: '\64';
} /* 'd' */
.icon-feather-credit-card:before {
  content: '\65';
} /* 'e' */
.icon-feather-crosshair:before {
  content: '\66';
} /* 'f' */
.icon-feather-disc:before {
  content: '\67';
} /* 'g' */
.icon-feather-delete:before {
  content: '\68';
} /* 'h' */
.icon-feather-download-cloud:before {
  content: '\69';
} /* 'i' */
.icon-feather-download:before {
  content: '\6a';
} /* 'j' */
.icon-feather-droplet:before {
  content: '\6b';
} /* 'k' */
.icon-feather-edit-:before {
  content: '\6c';
} /* 'l' */
.icon-feather-edit:before {
  content: '\6d';
} /* 'm' */
.icon-feather-edit-1:before {
  content: '\6e';
} /* 'n' */
.icon-feather-external-link:before {
  content: '\6f';
} /* 'o' */
.icon-feather-eye:before {
  content: '\70';
} /* 'p' */
.icon-feather-feather:before {
  content: '\71';
} /* 'q' */
.icon-feather-facebook:before {
  content: '\72';
} /* 'r' */
.icon-feather-file-minus:before {
  content: '\73';
} /* 's' */
.icon-feather-eye-off:before {
  content: '\74';
} /* 't' */
.icon-feather-fast-forward:before {
  content: '\75';
} /* 'u' */
.icon-feather-file-text:before {
  content: '\76';
} /* 'v' */
.icon-feather-film:before {
  content: '\77';
} /* 'w' */
.icon-feather-file:before {
  content: '\79';
} /* 'y' */
.icon-feather-file-plus:before {
  content: '\7a';
} /* 'z' */
.icon-feather-folder:before {
  content: '\7b';
} /* '{' */
.icon-feather-filter:before {
  content: '\7c';
} /* '|' */
.icon-feather-flag:before {
  content: '\7d';
} /* '}' */
.icon-feather-globe:before {
  content: '\7e';
} /* '~' */
.icon-feather-grid:before {
  content: '\e800';
} /* '' */
.icon-feather-heart:before {
  content: '\e801';
} /* '' */
.icon-feather-home:before {
  content: '\e802';
} /* '' */
.icon-feather-github:before {
  content: '\e803';
} /* '' */
.icon-feather-image:before {
  content: '\e804';
} /* '' */
.icon-feather-inbox:before {
  content: '\e805';
} /* '' */
.icon-feather-layers:before {
  content: '\e806';
} /* '' */
.icon-feather-info:before {
  content: '\e807';
} /* '' */
.icon-feather-instagram:before {
  content: '\e808';
} /* '' */
.icon-feather-layout:before {
  content: '\e809';
} /* '' */
.icon-feather-link-:before {
  content: '\e80a';
} /* '' */
.icon-feather-life-buoy:before {
  content: '\e80b';
} /* '' */
.icon-feather-link:before {
  content: '\e80c';
} /* '' */
.icon-feather-log-in:before {
  content: '\e80d';
} /* '' */
.icon-feather-list:before {
  content: '\e80e';
} /* '' */
.icon-feather-lock:before {
  content: '\e80f';
} /* '' */
.icon-feather-log-out:before {
  content: '\e810';
} /* '' */
.icon-feather-loader:before {
  content: '\e811';
} /* '' */
.icon-feather-mail:before {
  content: '\e812';
} /* '' */
.icon-feather-maximize-:before {
  content: '\e813';
} /* '' */
.icon-feather-map:before {
  content: '\e814';
} /* '' */
.icon-feather-map-pin:before {
  content: '\e815';
} /* '' */
.icon-feather-menu:before {
  content: '\e816';
} /* '' */
.icon-feather-message-circle:before {
  content: '\e817';
} /* '' */
.icon-feather-message-square:before {
  content: '\e818';
} /* '' */
.icon-feather-minimize-:before {
  content: '\e819';
} /* '' */
.icon-feather-mic-off:before {
  content: '\e81a';
} /* '' */
.icon-feather-minus-circle:before {
  content: '\e81b';
} /* '' */
.icon-feather-mic:before {
  content: '\e81c';
} /* '' */
.icon-feather-minus-square:before {
  content: '\e81d';
} /* '' */
.icon-feather-minus:before {
  content: '\e81e';
} /* '' */
.icon-feather-moon:before {
  content: '\e81f';
} /* '' */
.icon-feather-monitor:before {
  content: '\e820';
} /* '' */
.icon-feather-more-vertical:before {
  content: '\e821';
} /* '' */
.icon-feather-more-horizontal:before {
  content: '\e822';
} /* '' */
.icon-feather-move:before {
  content: '\e823';
} /* '' */
.icon-feather-music:before {
  content: '\e824';
} /* '' */
.icon-feather-navigation-:before {
  content: '\e825';
} /* '' */
.icon-feather-navigation:before {
  content: '\e826';
} /* '' */
.icon-feather-octagon:before {
  content: '\e827';
} /* '' */
.icon-feather-package:before {
  content: '\e828';
} /* '' */
.icon-feather-pause-circle:before {
  content: '\e829';
} /* '' */
.icon-feather-pause:before {
  content: '\e82a';
} /* '' */
.icon-feather-percent:before {
  content: '\e82b';
} /* '' */
.icon-feather-phone-call:before {
  content: '\e82c';
} /* '' */
.icon-feather-phone-forwarded:before {
  content: '\e82d';
} /* '' */
.icon-feather-phone-missed:before {
  content: '\e82e';
} /* '' */
.icon-feather-phone-off:before {
  content: '\e82f';
} /* '' */
.icon-feather-phone-incoming:before {
  content: '\e830';
} /* '' */
.icon-feather-phone:before {
  content: '\e831';
} /* '' */
.icon-feather-phone-outgoing:before {
  content: '\e832';
} /* '' */
.icon-feather-pie-chart:before {
  content: '\e833';
} /* '' */
.icon-feather-play-circle:before {
  content: '\e834';
} /* '' */
.icon-feather-play:before {
  content: '\e835';
} /* '' */
.icon-feather-plus-square:before {
  content: '\e836';
} /* '' */
.icon-feather-plus-circle:before {
  content: '\e837';
} /* '' */
.icon-feather-plus:before {
  content: '\e838';
} /* '' */
.icon-feather-pocket:before {
  content: '\e839';
} /* '' */
.icon-feather-printer:before {
  content: '\e83a';
} /* '' */
.icon-feather-power:before {
  content: '\e83b';
} /* '' */
.icon-feather-radio:before {
  content: '\e83c';
} /* '' */
.icon-feather-repeat:before {
  content: '\e83d';
} /* '' */
.icon-feather-refresh-ccw:before {
  content: '\e83e';
} /* '' */
.icon-feather-rewind:before {
  content: '\e83f';
} /* '' */
.icon-feather-rotate-ccw:before {
  content: '\e840';
} /* '' */
.icon-feather-refresh-cw:before {
  content: '\e841';
} /* '' */
.icon-feather-rotate-cw:before {
  content: '\e842';
} /* '' */
.icon-feather-save:before {
  content: '\e843';
} /* '' */
.icon-feather-search:before {
  content: '\e844';
} /* '' */
.icon-feather-server:before {
  content: '\e845';
} /* '' */
.icon-feather-scissors:before {
  content: '\e846';
} /* '' */
.icon-feather-share-:before {
  content: '\e847';
} /* '' */
.icon-feather-share:before {
  content: '\e848';
} /* '' */
.icon-feather-shield:before {
  content: '\e849';
} /* '' */
.icon-feather-settings:before {
  content: '\e84a';
} /* '' */
.icon-feather-skip-back:before {
  content: '\e84b';
} /* '' */
.icon-feather-shuffle:before {
  content: '\e84c';
} /* '' */
.icon-feather-sidebar:before {
  content: '\e84d';
} /* '' */
.icon-feather-skip-forward:before {
  content: '\e84e';
} /* '' */
.icon-feather-slack:before {
  content: '\e84f';
} /* '' */
.icon-feather-slash:before {
  content: '\e850';
} /* '' */
.icon-feather-smartphone:before {
  content: '\e851';
} /* '' */
.icon-feather-square:before {
  content: '\e852';
} /* '' */
.icon-feather-speaker:before {
  content: '\e853';
} /* '' */
.icon-feather-star:before {
  content: '\e854';
} /* '' */
.icon-feather-stop-circle:before {
  content: '\e855';
} /* '' */
.icon-feather-sun:before {
  content: '\e856';
} /* '' */
.icon-feather-sunrise:before {
  content: '\e857';
} /* '' */
.icon-feather-tablet:before {
  content: '\e858';
} /* '' */
.icon-feather-tag:before {
  content: '\e859';
} /* '' */
.icon-feather-sunset:before {
  content: '\e85a';
} /* '' */
.icon-feather-target:before {
  content: '\e85b';
} /* '' */
.icon-feather-thermometer:before {
  content: '\e85c';
} /* '' */
.icon-feather-thumbs-up:before {
  content: '\e85d';
} /* '' */
.icon-feather-thumbs-down:before {
  content: '\e85e';
} /* '' */
.icon-feather-toggle-left:before {
  content: '\e85f';
} /* '' */
.icon-feather-toggle-right:before {
  content: '\e860';
} /* '' */
.icon-feather-trash-:before {
  content: '\e861';
} /* '' */
.icon-feather-trash:before {
  content: '\e862';
} /* '' */
.icon-feather-trending-up:before {
  content: '\e863';
} /* '' */
.icon-feather-trending-down:before {
  content: '\e864';
} /* '' */
.icon-feather-triangle:before {
  content: '\e865';
} /* '' */
.icon-feather-type:before {
  content: '\e866';
} /* '' */
.icon-feather-twitter:before {
  content: '\e867';
} /* '' */
.icon-feather-upload:before {
  content: '\e868';
} /* '' */
.icon-feather-umbrella:before {
  content: '\e869';
} /* '' */
.icon-feather-upload-cloud:before {
  content: '\e86a';
} /* '' */
.icon-feather-unlock:before {
  content: '\e86b';
} /* '' */
.icon-feather-user-check:before {
  content: '\e86c';
} /* '' */
.icon-feather-user-minus:before {
  content: '\e86d';
} /* '' */
.icon-feather-user-plus:before {
  content: '\e86e';
} /* '' */
.icon-feather-user-x:before {
  content: '\e86f';
} /* '' */
.icon-feather-user:before {
  content: '\e870';
} /* '' */
.icon-feather-users:before {
  content: '\e871';
} /* '' */
.icon-feather-video-off:before {
  content: '\e872';
} /* '' */
.icon-feather-video:before {
  content: '\e873';
} /* '' */
.icon-feather-voicemail:before {
  content: '\e874';
} /* '' */
.icon-feather-volume-x:before {
  content: '\e875';
} /* '' */
.icon-feather-volume-:before {
  content: '\e876';
} /* '' */
.icon-feather-volume-1:before {
  content: '\e877';
} /* '' */
.icon-feather-volume:before {
  content: '\e878';
} /* '' */
.icon-feather-watch:before {
  content: '\e879';
} /* '' */
.icon-feather-wifi:before {
  content: '\e87a';
} /* '' */
.icon-feather-x-square:before {
  content: '\e87b';
} /* '' */
.icon-feather-wind:before {
  content: '\e87c';
} /* '' */
.icon-feather-x:before {
  content: '\e87d';
} /* '' */
.icon-feather-x-circle:before {
  content: '\e87e';
} /* '' */
.icon-feather-zap:before {
  content: '\e87f';
} /* '' */
.icon-feather-zoom-in:before {
  content: '\e880';
} /* '' */
.icon-feather-zoom-out:before {
  content: '\e881';
} /* '' */
.icon-feather-command:before {
  content: '\e882';
} /* '' */
.icon-feather-cloud:before {
  content: '\e883';
} /* '' */
.icon-feather-hash:before {
  content: '\e884';
} /* '' */
.icon-feather-headphones:before {
  content: '\e885';
} /* '' */
.icon-feather-underline:before {
  content: '\e886';
} /* '' */
.icon-feather-italic:before {
  content: '\e887';
} /* '' */
.icon-feather-bold:before {
  content: '\e888';
} /* '' */
.icon-feather-crop:before {
  content: '\e889';
} /* '' */
.icon-feather-help-circle:before {
  content: '\e88a';
} /* '' */
.icon-feather-paperclip:before {
  content: '\e88b';
} /* '' */
.icon-feather-shopping-cart:before {
  content: '\e88c';
} /* '' */
.icon-feather-tv:before {
  content: '\e88d';
} /* '' */
.icon-feather-wifi-off:before {
  content: '\e88e';
} /* '' */
.icon-feather-minimize:before {
  content: '\e88f';
} /* '' */
.icon-feather-maximize:before {
  content: '\e890';
} /* '' */
.icon-feather-gitlab:before {
  content: '\e891';
} /* '' */
.icon-feather-sliders:before {
  content: '\e892';
} /* '' */
.icon-feather-star-on:before {
  content: '\e893';
} /* '' */
.icon-feather-heart-on:before {
  content: '\e894';
} /* '' */
.icon-feather-archive:before {
  content: '\e895';
} /* '' */
.icon-feather-arrow-down-circle:before {
  content: '\e896';
} /* '' */
.icon-feather-arrow-up-circle:before {
  content: '\e897';
} /* '' */
.icon-feather-arrow-left-circle:before {
  content: '\e898';
} /* '' */
.icon-feather-arrow-right-circle:before {
  content: '\e899';
} /* '' */
.icon-feather-bar-chart-line-2:before {
  content: '\e89a';
} /* '' */
.icon-feather-bar-chart-line:before {
  content: '\e89b';
} /* '' */
.icon-feather-book-open:before {
  content: '\e89c';
} /* '' */
.icon-feather-code:before {
  content: '\e89d';
} /* '' */
.icon-feather-database:before {
  content: '\e89e';
} /* '' */
.icon-feather-dollar-sign:before {
  content: '\e89f';
} /* '' */
.icon-feather-folder-plus:before {
  content: '\e8a0';
} /* '' */
.icon-feather-gift:before {
  content: '\e8a1';
} /* '' */
.icon-feather-folder-minus:before {
  content: '\e8a2';
} /* '' */
.icon-feather-git-commit:before {
  content: '\e8a3';
} /* '' */
.icon-feather-git-branch:before {
  content: '\e8a4';
} /* '' */
.icon-feather-git-pull-request:before {
  content: '\e8a5';
} /* '' */
.icon-feather-git-merge:before {
  content: '\e8a6';
} /* '' */
.icon-feather-linkedin:before {
  content: '\e8a7';
} /* '' */
.icon-feather-hard-drive:before {
  content: '\e8a8';
} /* '' */
.icon-feather-more-vertical-:before {
  content: '\e8a9';
} /* '' */
.icon-feather-more-horizontal-:before {
  content: '\e8aa';
} /* '' */
.icon-feather-rss:before {
  content: '\e8ab';
} /* '' */
.icon-feather-send:before {
  content: '\e8ac';
} /* '' */
.icon-feather-shield-off:before {
  content: '\e8ad';
} /* '' */
.icon-feather-shopping-bag:before {
  content: '\e8ae';
} /* '' */
.icon-feather-terminal:before {
  content: '\e8af';
} /* '' */
.icon-feather-truck:before {
  content: '\e8b0';
} /* '' */
.icon-feather-zap-off:before {
  content: '\e8b1';
} /* '' */
.icon-feather-youtube:before {
  content: '\e8b2';
} /* '' */
.icon-unicons-abacus:before {
  content: '\e8b3';
} /* '' */
.icon-unicons-accessible-icon-alt:before {
  content: '\e8b4';
} /* '' */
.icon-unicons-adjust:before {
  content: '\e8b5';
} /* '' */
.icon-unicons-adjust-alt:before {
  content: '\e8b6';
} /* '' */
.icon-unicons-adjust-circle:before {
  content: '\e8b7';
} /* '' */
.icon-unicons-adjust-half:before {
  content: '\e8b8';
} /* '' */
.icon-unicons-adobe:before {
  content: '\e8b9';
} /* '' */
.icon-unicons-adobe-alt:before {
  content: '\e8ba';
} /* '' */
.icon-unicons-0-plus:before {
  content: '\e8bb';
} /* '' */
.icon-unicons-3-plus:before {
  content: '\e8bc';
} /* '' */
.icon-unicons-6-plus:before {
  content: '\e8bd';
} /* '' */
.icon-unicons-10-plus:before {
  content: '\e8be';
} /* '' */
.icon-unicons-12-plus:before {
  content: '\e8bf';
} /* '' */
.icon-unicons-13-plus:before {
  content: '\e8c0';
} /* '' */
.icon-unicons-16-plus:before {
  content: '\e8c1';
} /* '' */
.icon-unicons-17-plus:before {
  content: '\e8c2';
} /* '' */
.icon-unicons-18-plus:before {
  content: '\e8c3';
} /* '' */
.icon-unicons-21-plus:before {
  content: '\e8c4';
} /* '' */
.icon-unicons-500px:before {
  content: '\e8c5';
} /* '' */
.icon-unicons-align-left-justify:before {
  content: '\e8c6';
} /* '' */
.icon-unicons-align-letter-right:before {
  content: '\e8c7';
} /* '' */
.icon-unicons-align-right:before {
  content: '\e8c8';
} /* '' */
.icon-unicons-align-right-justify:before {
  content: '\e8c9';
} /* '' */
.icon-unicons-airplay:before {
  content: '\e8ca';
} /* '' */
.icon-unicons-align:before {
  content: '\e8cb';
} /* '' */
.icon-unicons-align-alt:before {
  content: '\e8cc';
} /* '' */
.icon-unicons-align-center:before {
  content: '\e8cd';
} /* '' */
.icon-unicons-align-center-alt:before {
  content: '\e8ce';
} /* '' */
.icon-unicons-align-center-h:before {
  content: '\e8cf';
} /* '' */
.icon-unicons-align-center-justify:before {
  content: '\e8d0';
} /* '' */
.icon-unicons-align-center-v:before {
  content: '\e8d1';
} /* '' */
.icon-unicons-align-justify:before {
  content: '\e8d2';
} /* '' */
.icon-unicons-align-left:before {
  content: '\e8d3';
} /* '' */
.icon-unicons-archive-alt:before {
  content: '\e8d4';
} /* '' */
.icon-unicons-archway:before {
  content: '\e8d5';
} /* '' */
.icon-unicons-arrow:before {
  content: '\e8d6';
} /* '' */
.icon-unicons-arrow-break:before {
  content: '\e8d7';
} /* '' */
.icon-unicons-amazon:before {
  content: '\e8d8';
} /* '' */
.icon-unicons-ambulance:before {
  content: '\e8d9';
} /* '' */
.icon-unicons-analysis:before {
  content: '\e8da';
} /* '' */
.icon-unicons-analytics:before {
  content: '\e8db';
} /* '' */
.icon-unicons-anchor:before {
  content: '\e8dc';
} /* '' */
.icon-unicons-android:before {
  content: '\e8dd';
} /* '' */
.icon-unicons-android-alt:before {
  content: '\e8de';
} /* '' */
.icon-unicons-android-phone-slash:before {
  content: '\e8df';
} /* '' */
.icon-unicons-angle-double-down:before {
  content: '\e8e0';
} /* '' */
.icon-unicons-angle-double-left:before {
  content: '\e8e1';
} /* '' */
.icon-unicons-angle-double-right:before {
  content: '\e8e2';
} /* '' */
.icon-unicons-angle-double-up:before {
  content: '\e8e3';
} /* '' */
.icon-unicons-angle-down:before {
  content: '\e8e4';
} /* '' */
.icon-unicons-angle-left:before {
  content: '\e8e5';
} /* '' */
.icon-unicons-angle-left-b:before {
  content: '\e8e6';
} /* '' */
.icon-unicons-angle-right:before {
  content: '\e8e7';
} /* '' */
.icon-unicons-angle-right-b:before {
  content: '\e8e8';
} /* '' */
.icon-unicons-angle-up:before {
  content: '\e8e9';
} /* '' */
.icon-unicons-angry:before {
  content: '\e8ea';
} /* '' */
.icon-unicons-ankh:before {
  content: '\e8eb';
} /* '' */
.icon-unicons-annoyed:before {
  content: '\e8ec';
} /* '' */
.icon-unicons-annoyed-alt:before {
  content: '\e8ed';
} /* '' */
.icon-unicons-apple:before {
  content: '\e8ee';
} /* '' */
.icon-unicons-apple-alt:before {
  content: '\e8ef';
} /* '' */
.icon-unicons-apps:before {
  content: '\e8f0';
} /* '' */
.icon-unicons-archive:before {
  content: '\e8f1';
} /* '' */
.icon-unicons-arrow-circle-left:before {
  content: '\e8f2';
} /* '' */
.icon-unicons-arrow-circle-right:before {
  content: '\e8f3';
} /* '' */
.icon-unicons-arrow-circle-up:before {
  content: '\e8f4';
} /* '' */
.icon-unicons-arrow-circle-down:before {
  content: '\e8f5';
} /* '' */
.icon-unicons-arrows-h:before {
  content: '\e8f6';
} /* '' */
.icon-unicons-arrows-h-alt:before {
  content: '\e8f7';
} /* '' */
.icon-unicons-arrows-left-down:before {
  content: '\e8f8';
} /* '' */
.icon-unicons-arrows-maximize:before {
  content: '\e8f9';
} /* '' */
.icon-unicons-arrows-merge:before {
  content: '\e8fa';
} /* '' */
.icon-unicons-arrows-resize:before {
  content: '\e8fb';
} /* '' */
.icon-unicons-arrows-resize-h:before {
  content: '\e8fc';
} /* '' */
.icon-unicons-arrows-resize-v:before {
  content: '\e8fd';
} /* '' */
.icon-unicons-arrows-right-down:before {
  content: '\e8fe';
} /* '' */
.icon-unicons-arrow-compress-h:before {
  content: '\e8ff';
} /* '' */
.icon-unicons-arrow-down:before {
  content: '\e900';
} /* '' */
.icon-unicons-arrow-down-left:before {
  content: '\e901';
} /* '' */
.icon-unicons-arrow-down-right:before {
  content: '\e902';
} /* '' */
.icon-unicons-arrow-from-right:before {
  content: '\e903';
} /* '' */
.icon-unicons-arrow-from-top:before {
  content: '\e904';
} /* '' */
.icon-unicons-arrow-growth:before {
  content: '\e905';
} /* '' */
.icon-unicons-arrow-left:before {
  content: '\e906';
} /* '' */
.icon-unicons-arrow-random:before {
  content: '\e907';
} /* '' */
.icon-unicons-arrow-resize-diagonal:before {
  content: '\e908';
} /* '' */
.icon-unicons-arrow-right:before {
  content: '\e909';
} /* '' */
.icon-unicons-baby-carriage:before {
  content: '\e90a';
} /* '' */
.icon-unicons-backpack:before {
  content: '\e90b';
} /* '' */
.icon-unicons-backspace:before {
  content: '\e90c';
} /* '' */
.icon-unicons-arrows-shrink-h:before {
  content: '\e90d';
} /* '' */
.icon-unicons-arrows-shrink-v:before {
  content: '\e90e';
} /* '' */
.icon-unicons-arrows-up-right:before {
  content: '\e90f';
} /* '' */
.icon-unicons-arrows-v:before {
  content: '\e910';
} /* '' */
.icon-unicons-arrows-v-alt:before {
  content: '\e911';
} /* '' */
.icon-unicons-arrow-to-bottom:before {
  content: '\e912';
} /* '' */
.icon-unicons-arrow-to-right:before {
  content: '\e913';
} /* '' */
.icon-unicons-arrow-up:before {
  content: '\e914';
} /* '' */
.icon-unicons-arrow-up-left:before {
  content: '\e915';
} /* '' */
.icon-unicons-arrow-up-right:before {
  content: '\e916';
} /* '' */
.icon-unicons-assistive-listening-systems:before {
  content: '\e917';
} /* '' */
.icon-unicons-asterisk:before {
  content: '\e918';
} /* '' */
.icon-unicons-at:before {
  content: '\e919';
} /* '' */
.icon-unicons-atom:before {
  content: '\e91a';
} /* '' */
.icon-unicons-auto-flash:before {
  content: '\e91b';
} /* '' */
.icon-unicons-award:before {
  content: '\e91c';
} /* '' */
.icon-unicons-award-alt:before {
  content: '\e91d';
} /* '' */
.icon-unicons-bed-double:before {
  content: '\e91e';
} /* '' */
.icon-unicons-behance:before {
  content: '\e91f';
} /* '' */
.icon-unicons-behance-alt:before {
  content: '\e920';
} /* '' */
.icon-unicons-bell:before {
  content: '\e921';
} /* '' */
.icon-unicons-bell-school:before {
  content: '\e922';
} /* '' */
.icon-unicons-backward:before {
  content: '\e923';
} /* '' */
.icon-unicons-bag:before {
  content: '\e924';
} /* '' */
.icon-unicons-bag-alt:before {
  content: '\e925';
} /* '' */
.icon-unicons-bag-slash:before {
  content: '\e926';
} /* '' */
.icon-unicons-balance-scale:before {
  content: '\e927';
} /* '' */
.icon-unicons-ban:before {
  content: '\e928';
} /* '' */
.icon-unicons-band-aid:before {
  content: '\e929';
} /* '' */
.icon-unicons-bars:before {
  content: '\e92a';
} /* '' */
.icon-unicons-baseball-ball:before {
  content: '\e92b';
} /* '' */
.icon-unicons-basketball:before {
  content: '\e92c';
} /* '' */
.icon-unicons-basketball-hoop:before {
  content: '\e92d';
} /* '' */
.icon-unicons-bath:before {
  content: '\e92e';
} /* '' */
.icon-unicons-battery-bolt:before {
  content: '\e92f';
} /* '' */
.icon-unicons-battery-empty:before {
  content: '\e930';
} /* '' */
.icon-unicons-bed:before {
  content: '\e931';
} /* '' */
.icon-unicons-car-sideview:before {
  content: '\e932';
} /* '' */
.icon-unicons-car-slash:before {
  content: '\e933';
} /* '' */
.icon-unicons-car-wash:before {
  content: '\e934';
} /* '' */
.icon-unicons-cell:before {
  content: '\e935';
} /* '' */
.icon-unicons-celsius:before {
  content: '\e936';
} /* '' */
.icon-unicons-channel:before {
  content: '\e937';
} /* '' */
.icon-unicons-channel-add:before {
  content: '\e938';
} /* '' */
.icon-unicons-bell-slash:before {
  content: '\e939';
} /* '' */
.icon-unicons-bill:before {
  content: '\e93a';
} /* '' */
.icon-unicons-bing:before {
  content: '\e93b';
} /* '' */
.icon-unicons-bitcoin:before {
  content: '\e93c';
} /* '' */
.icon-unicons-bitcoin-alt:before {
  content: '\e93d';
} /* '' */
.icon-unicons-bitcoin-circle:before {
  content: '\e93e';
} /* '' */
.icon-unicons-bitcoin-sign:before {
  content: '\e93f';
} /* '' */
.icon-unicons-black-berry:before {
  content: '\e940';
} /* '' */
.icon-unicons-blogger:before {
  content: '\e941';
} /* '' */
.icon-unicons-blogger-alt:before {
  content: '\e942';
} /* '' */
.icon-unicons-bluetooth-b:before {
  content: '\e943';
} /* '' */
.icon-unicons-bold:before {
  content: '\e944';
} /* '' */
.icon-unicons-bolt:before {
  content: '\e945';
} /* '' */
.icon-unicons-bolt-alt:before {
  content: '\e946';
} /* '' */
.icon-unicons-bolt-slash:before {
  content: '\e947';
} /* '' */
.icon-unicons-book:before {
  content: '\e948';
} /* '' */
.icon-unicons-book-alt:before {
  content: '\e949';
} /* '' */
.icon-unicons-bookmark:before {
  content: '\e94a';
} /* '' */
.icon-unicons-bookmark-full:before {
  content: '\e94b';
} /* '' */
.icon-unicons-book-medical:before {
  content: '\e94c';
} /* '' */
.icon-unicons-book-open:before {
  content: '\e94d';
} /* '' */
.icon-unicons-book-reader:before {
  content: '\e94e';
} /* '' */
.icon-unicons-books:before {
  content: '\e94f';
} /* '' */
.icon-unicons-boombox:before {
  content: '\e950';
} /* '' */
.icon-unicons-border-alt:before {
  content: '\e951';
} /* '' */
.icon-unicons-border-bottom:before {
  content: '\e952';
} /* '' */
.icon-unicons-border-clear:before {
  content: '\e953';
} /* '' */
.icon-unicons-border-horizontal:before {
  content: '\e954';
} /* '' */
.icon-unicons-border-inner:before {
  content: '\e955';
} /* '' */
.icon-unicons-border-left:before {
  content: '\e956';
} /* '' */
.icon-unicons-border-out:before {
  content: '\e957';
} /* '' */
.icon-unicons-border-right:before {
  content: '\e958';
} /* '' */
.icon-unicons-border-top:before {
  content: '\e959';
} /* '' */
.icon-unicons-border-vertical:before {
  content: '\e95a';
} /* '' */
.icon-unicons-bowling-ball:before {
  content: '\e95b';
} /* '' */
.icon-unicons-box:before {
  content: '\e95c';
} /* '' */
.icon-unicons-brackets-curly:before {
  content: '\e95d';
} /* '' */
.icon-unicons-brain:before {
  content: '\e95e';
} /* '' */
.icon-unicons-briefcase:before {
  content: '\e95f';
} /* '' */
.icon-unicons-briefcase-alt:before {
  content: '\e960';
} /* '' */
.icon-unicons-bright:before {
  content: '\e961';
} /* '' */
.icon-unicons-brightness:before {
  content: '\e962';
} /* '' */
.icon-unicons-brightness-empty:before {
  content: '\e963';
} /* '' */
.icon-unicons-brightness-half:before {
  content: '\e964';
} /* '' */
.icon-unicons-brightness-low:before {
  content: '\e965';
} /* '' */
.icon-unicons-brightness-minus:before {
  content: '\e966';
} /* '' */
.icon-unicons-brightness-plus:before {
  content: '\e967';
} /* '' */
.icon-unicons-bring-bottom:before {
  content: '\e968';
} /* '' */
.icon-unicons-bring-front:before {
  content: '\e969';
} /* '' */
.icon-unicons-browser:before {
  content: '\e96a';
} /* '' */
.icon-unicons-brush-alt:before {
  content: '\e96b';
} /* '' */
.icon-unicons-bug:before {
  content: '\e96c';
} /* '' */
.icon-unicons-building:before {
  content: '\e96d';
} /* '' */
.icon-unicons-bullseye:before {
  content: '\e96e';
} /* '' */
.icon-unicons-bus:before {
  content: '\e96f';
} /* '' */
.icon-unicons-bus-alt:before {
  content: '\e970';
} /* '' */
.icon-unicons-bus-school:before {
  content: '\e971';
} /* '' */
.icon-unicons-calculator:before {
  content: '\e972';
} /* '' */
.icon-unicons-calculator-alt:before {
  content: '\e973';
} /* '' */
.icon-unicons-calendar-alt:before {
  content: '\e974';
} /* '' */
.icon-unicons-calendar-slash:before {
  content: '\e975';
} /* '' */
.icon-unicons-calender:before {
  content: '\e976';
} /* '' */
.icon-unicons-calling:before {
  content: '\e977';
} /* '' */
.icon-unicons-camera:before {
  content: '\e978';
} /* '' */
.icon-unicons-camera-change:before {
  content: '\e979';
} /* '' */
.icon-unicons-camera-plus:before {
  content: '\e97a';
} /* '' */
.icon-unicons-camera-slash:before {
  content: '\e97b';
} /* '' */
.icon-unicons-cancel:before {
  content: '\e97c';
} /* '' */
.icon-unicons-capsule:before {
  content: '\e97d';
} /* '' */
.icon-unicons-capture:before {
  content: '\e97e';
} /* '' */
.icon-unicons-car:before {
  content: '\e97f';
} /* '' */
.icon-unicons-card-atm:before {
  content: '\e980';
} /* '' */
.icon-unicons-caret-right:before {
  content: '\e981';
} /* '' */
.icon-unicons-club:before {
  content: '\e982';
} /* '' */
.icon-unicons-code-branch:before {
  content: '\e983';
} /* '' */
.icon-unicons-coffee:before {
  content: '\e984';
} /* '' */
.icon-unicons-cog:before {
  content: '\e985';
} /* '' */
.icon-unicons-chart:before {
  content: '\e986';
} /* '' */
.icon-unicons-chart-bar:before {
  content: '\e987';
} /* '' */
.icon-unicons-chart-bar-alt:before {
  content: '\e988';
} /* '' */
.icon-unicons-chart-down:before {
  content: '\e989';
} /* '' */
.icon-unicons-chart-growth:before {
  content: '\e98a';
} /* '' */
.icon-unicons-chart-growth-alt:before {
  content: '\e98b';
} /* '' */
.icon-unicons-chart-line:before {
  content: '\e98c';
} /* '' */
.icon-unicons-chart-pie:before {
  content: '\e98d';
} /* '' */
.icon-unicons-chart-pie-alt:before {
  content: '\e98e';
} /* '' */
.icon-unicons-chat:before {
  content: '\e98f';
} /* '' */
.icon-unicons-chat-bubble-user:before {
  content: '\e990';
} /* '' */
.icon-unicons-chat-info:before {
  content: '\e991';
} /* '' */
.icon-unicons-check:before {
  content: '\e992';
} /* '' */
.icon-unicons-check-circle:before {
  content: '\e993';
} /* '' */
.icon-unicons-check-square:before {
  content: '\e994';
} /* '' */
.icon-unicons-circle:before {
  content: '\e995';
} /* '' */
.icon-unicons-circle-layer:before {
  content: '\e996';
} /* '' */
.icon-unicons-circuit:before {
  content: '\e997';
} /* '' */
.icon-unicons-clapper-board:before {
  content: '\e998';
} /* '' */
.icon-unicons-clinic-medical:before {
  content: '\e999';
} /* '' */
.icon-unicons-clipboard:before {
  content: '\e99a';
} /* '' */
.icon-unicons-clipboard-alt:before {
  content: '\e99b';
} /* '' */
.icon-unicons-clipboard-blank:before {
  content: '\e99c';
} /* '' */
.icon-unicons-clipboard-notes:before {
  content: '\e99d';
} /* '' */
.icon-unicons-clock:before {
  content: '\e99e';
} /* '' */
.icon-unicons-clock-eight:before {
  content: '\e99f';
} /* '' */
.icon-unicons-clock-five:before {
  content: '\e9a0';
} /* '' */
.icon-unicons-clock-nine:before {
  content: '\e9a1';
} /* '' */
.icon-unicons-clock-seven:before {
  content: '\e9a2';
} /* '' */
.icon-unicons-clock-ten:before {
  content: '\e9a3';
} /* '' */
.icon-unicons-clock-three:before {
  content: '\e9a4';
} /* '' */
.icon-unicons-clock-two:before {
  content: '\e9a5';
} /* '' */
.icon-unicons-closed-captioning:before {
  content: '\e9a6';
} /* '' */
.icon-unicons-closed-captioning-slash:before {
  content: '\e9a7';
} /* '' */
.icon-unicons-cloud:before {
  content: '\e9a8';
} /* '' */
.icon-unicons-cloud-block:before {
  content: '\e9a9';
} /* '' */
.icon-unicons-cloud-bookmark:before {
  content: '\e9aa';
} /* '' */
.icon-unicons-cloud-check:before {
  content: '\e9ab';
} /* '' */
.icon-unicons-cloud-computing:before {
  content: '\e9ac';
} /* '' */
.icon-unicons-cloud-database-tree:before {
  content: '\e9ad';
} /* '' */
.icon-unicons-cloud-data-connection:before {
  content: '\e9ae';
} /* '' */
.icon-unicons-cloud-download:before {
  content: '\e9af';
} /* '' */
.icon-unicons-cloud-drizzle:before {
  content: '\e9b0';
} /* '' */
.icon-unicons-cloud-exclamation:before {
  content: '\e9b1';
} /* '' */
.icon-unicons-cloud-hail:before {
  content: '\e9b2';
} /* '' */
.icon-unicons-cloud-heart:before {
  content: '\e9b3';
} /* '' */
.icon-unicons-cloud-info:before {
  content: '\e9b4';
} /* '' */
.icon-unicons-cloud-lock:before {
  content: '\e9b5';
} /* '' */
.icon-unicons-cloud-meatball:before {
  content: '\e9b6';
} /* '' */
.icon-unicons-cloud-moon:before {
  content: '\e9b7';
} /* '' */
.icon-unicons-cloud-moon-hail:before {
  content: '\e9b8';
} /* '' */
.icon-unicons-cloud-moon-meatball:before {
  content: '\e9b9';
} /* '' */
.icon-unicons-cloud-moon-rain:before {
  content: '\e9ba';
} /* '' */
.icon-unicons-cloud-moon-showers:before {
  content: '\e9bb';
} /* '' */
.icon-unicons-cloud-question:before {
  content: '\e9bc';
} /* '' */
.icon-unicons-cloud-rain:before {
  content: '\e9bd';
} /* '' */
.icon-unicons-cloud-rain-sun:before {
  content: '\e9be';
} /* '' */
.icon-unicons-cloud-redo:before {
  content: '\e9bf';
} /* '' */
.icon-unicons-clouds:before {
  content: '\e9c0';
} /* '' */
.icon-unicons-cloud-share:before {
  content: '\e9c1';
} /* '' */
.icon-unicons-cloud-shield:before {
  content: '\e9c2';
} /* '' */
.icon-unicons-cloud-showers:before {
  content: '\e9c3';
} /* '' */
.icon-unicons-cloud-showers-alt:before {
  content: '\e9c4';
} /* '' */
.icon-unicons-cloud-showers-heavy:before {
  content: '\e9c5';
} /* '' */
.icon-unicons-cloud-slash:before {
  content: '\e9c6';
} /* '' */
.icon-unicons-cloud-sun:before {
  content: '\e9c7';
} /* '' */
.icon-unicons-cloud-sun-hail:before {
  content: '\e9c8';
} /* '' */
.icon-unicons-cloud-sun-meatball:before {
  content: '\e9c9';
} /* '' */
.icon-unicons-cloud-sun-rain:before {
  content: '\e9ca';
} /* '' */
.icon-unicons-cloud-sun-rain-alt:before {
  content: '\e9cb';
} /* '' */
.icon-unicons-cloud-sun-tear:before {
  content: '\e9cc';
} /* '' */
.icon-unicons-cloud-times:before {
  content: '\e9cd';
} /* '' */
.icon-unicons-cloud-unlock:before {
  content: '\e9ce';
} /* '' */
.icon-unicons-cloud-upload:before {
  content: '\e9cf';
} /* '' */
.icon-unicons-cloud-wifi:before {
  content: '\e9d0';
} /* '' */
.icon-unicons-cloud-wind:before {
  content: '\e9d1';
} /* '' */
.icon-unicons-copy-landscape:before {
  content: '\e9d2';
} /* '' */
.icon-unicons-copyright:before {
  content: '\e9d3';
} /* '' */
.icon-unicons-corner-down-left:before {
  content: '\e9d4';
} /* '' */
.icon-unicons-corner-down-right:before {
  content: '\e9d5';
} /* '' */
.icon-unicons-corner-down-right-alt:before {
  content: '\e9d6';
} /* '' */
.icon-unicons-corner-left-down:before {
  content: '\e9d7';
} /* '' */
.icon-unicons-corner-right-down:before {
  content: '\e9d8';
} /* '' */
.icon-unicons-corner-up-left:before {
  content: '\e9d9';
} /* '' */
.icon-unicons-corner-up-left-alt:before {
  content: '\e9da';
} /* '' */
.icon-unicons-corner-up-right:before {
  content: '\e9db';
} /* '' */
.icon-unicons-coins:before {
  content: '\e9dc';
} /* '' */
.icon-unicons-columns:before {
  content: '\e9dd';
} /* '' */
.icon-unicons-comment:before {
  content: '\e9de';
} /* '' */
.icon-unicons-comment-add:before {
  content: '\e9df';
} /* '' */
.icon-unicons-comment-alt:before {
  content: '\e9e0';
} /* '' */
.icon-unicons-comment-alt-block:before {
  content: '\e9e1';
} /* '' */
.icon-unicons-comment-alt-chart-lines:before {
  content: '\e9e2';
} /* '' */
.icon-unicons-comment-alt-check:before {
  content: '\e9e3';
} /* '' */
.icon-unicons-comment-alt-dots:before {
  content: '\e9e4';
} /* '' */
.icon-unicons-comment-alt-download:before {
  content: '\e9e5';
} /* '' */
.icon-unicons-comment-alt-edit:before {
  content: '\e9e6';
} /* '' */
.icon-unicons-comment-alt-exclamation:before {
  content: '\e9e7';
} /* '' */
.icon-unicons-comment-alt-heart:before {
  content: '\e9e8';
} /* '' */
.icon-unicons-comment-alt-image:before {
  content: '\e9e9';
} /* '' */
.icon-unicons-comment-alt-info:before {
  content: '\e9ea';
} /* '' */
.icon-unicons-comment-alt-lines:before {
  content: '\e9eb';
} /* '' */
.icon-unicons-comment-alt-lock:before {
  content: '\e9ec';
} /* '' */
.icon-unicons-comment-alt-medical:before {
  content: '\e9ed';
} /* '' */
.icon-unicons-comment-alt-message:before {
  content: '\e9ee';
} /* '' */
.icon-unicons-comment-alt-notes:before {
  content: '\e9ef';
} /* '' */
.icon-unicons-comment-alt-plus:before {
  content: '\e9f0';
} /* '' */
.icon-unicons-comment-alt-question:before {
  content: '\e9f1';
} /* '' */
.icon-unicons-comment-alt-redo:before {
  content: '\e9f2';
} /* '' */
.icon-unicons-comment-alt-search:before {
  content: '\e9f3';
} /* '' */
.icon-unicons-comment-alt-share:before {
  content: '\e9f4';
} /* '' */
.icon-unicons-comment-alt-shield:before {
  content: '\e9f5';
} /* '' */
.icon-unicons-comment-alt-slash:before {
  content: '\e9f6';
} /* '' */
.icon-unicons-comment-alt-upload:before {
  content: '\e9f7';
} /* '' */
.icon-unicons-comment-alt-verify:before {
  content: '\e9f8';
} /* '' */
.icon-unicons-comment-block:before {
  content: '\e9f9';
} /* '' */
.icon-unicons-comment-chart-line:before {
  content: '\e9fa';
} /* '' */
.icon-unicons-comment-check:before {
  content: '\e9fb';
} /* '' */
.icon-unicons-comment-dots:before {
  content: '\e9fc';
} /* '' */
.icon-unicons-comment-download:before {
  content: '\e9fd';
} /* '' */
.icon-unicons-comment-edit:before {
  content: '\e9fe';
} /* '' */
.icon-unicons-comment-exclamation:before {
  content: '\e9ff';
} /* '' */
.icon-unicons-comment-heart:before {
  content: '\ea00';
} /* '' */
.icon-unicons-comment-image:before {
  content: '\ea01';
} /* '' */
.icon-unicons-comment-info:before {
  content: '\ea02';
} /* '' */
.icon-unicons-comment-info-alt:before {
  content: '\ea03';
} /* '' */
.icon-unicons-comment-lines:before {
  content: '\ea04';
} /* '' */
.icon-unicons-comment-lock:before {
  content: '\ea05';
} /* '' */
.icon-unicons-comment-medical:before {
  content: '\ea06';
} /* '' */
.icon-unicons-comment-message:before {
  content: '\ea07';
} /* '' */
.icon-unicons-comment-notes:before {
  content: '\ea08';
} /* '' */
.icon-unicons-comment-plus:before {
  content: '\ea09';
} /* '' */
.icon-unicons-comment-question:before {
  content: '\ea0a';
} /* '' */
.icon-unicons-comment-redo:before {
  content: '\ea0b';
} /* '' */
.icon-unicons-comments:before {
  content: '\ea0c';
} /* '' */
.icon-unicons-comments-alt:before {
  content: '\ea0d';
} /* '' */
.icon-unicons-comment-search:before {
  content: '\ea0e';
} /* '' */
.icon-unicons-comment-share:before {
  content: '\ea0f';
} /* '' */
.icon-unicons-comment-shield:before {
  content: '\ea10';
} /* '' */
.icon-unicons-comment-slash:before {
  content: '\ea11';
} /* '' */
.icon-unicons-comment-upload:before {
  content: '\ea12';
} /* '' */
.icon-unicons-comment-verify:before {
  content: '\ea13';
} /* '' */
.icon-unicons-compact-disc:before {
  content: '\ea14';
} /* '' */
.icon-unicons-comparison:before {
  content: '\ea15';
} /* '' */
.icon-unicons-compass:before {
  content: '\ea16';
} /* '' */
.icon-unicons-compress:before {
  content: '\ea17';
} /* '' */
.icon-unicons-compress-alt:before {
  content: '\ea18';
} /* '' */
.icon-unicons-compress-alt-left:before {
  content: '\ea19';
} /* '' */
.icon-unicons-compress-arrows:before {
  content: '\ea1a';
} /* '' */
.icon-unicons-compress-lines:before {
  content: '\ea1b';
} /* '' */
.icon-unicons-compress-point:before {
  content: '\ea1c';
} /* '' */
.icon-unicons-compress-v:before {
  content: '\ea1d';
} /* '' */
.icon-unicons-confused:before {
  content: '\ea1e';
} /* '' */
.icon-unicons-constructor:before {
  content: '\ea1f';
} /* '' */
.icon-unicons-copy:before {
  content: '\ea20';
} /* '' */
.icon-unicons-copy-alt:before {
  content: '\ea21';
} /* '' */
.icon-unicons-envelope-info:before {
  content: '\ea22';
} /* '' */
.icon-unicons-envelope-lock:before {
  content: '\ea23';
} /* '' */
.icon-unicons-envelope-minus:before {
  content: '\ea24';
} /* '' */
.icon-unicons-envelope-open:before {
  content: '\ea25';
} /* '' */
.icon-unicons-envelope-question:before {
  content: '\ea26';
} /* '' */
.icon-unicons-corner-up-right-alt:before {
  content: '\ea27';
} /* '' */
.icon-unicons-coronavirus:before {
  content: '\ea28';
} /* '' */
.icon-unicons-create-dashboard:before {
  content: '\ea29';
} /* '' */
.icon-unicons-creative-commons-pd:before {
  content: '\ea2a';
} /* '' */
.icon-unicons-credit-card:before {
  content: '\ea2b';
} /* '' */
.icon-unicons-credit-card-search:before {
  content: '\ea2c';
} /* '' */
.icon-unicons-crockery:before {
  content: '\ea2d';
} /* '' */
.icon-unicons-crop-alt:before {
  content: '\ea2e';
} /* '' */
.icon-unicons-crop-alt-rotate-left:before {
  content: '\ea2f';
} /* '' */
.icon-unicons-crop-alt-rotate-right:before {
  content: '\ea30';
} /* '' */
.icon-unicons-crosshair:before {
  content: '\ea31';
} /* '' */
.icon-unicons-crosshair-alt:before {
  content: '\ea32';
} /* '' */
.icon-unicons-crosshairs:before {
  content: '\ea33';
} /* '' */
.icon-unicons-css3-simple:before {
  content: '\ea34';
} /* '' */
.icon-unicons-cube:before {
  content: '\ea35';
} /* '' */
.icon-unicons-dashboard:before {
  content: '\ea36';
} /* '' */
.icon-unicons-database:before {
  content: '\ea37';
} /* '' */
.icon-unicons-database-alt:before {
  content: '\ea38';
} /* '' */
.icon-unicons-data-sharing:before {
  content: '\ea39';
} /* '' */
.icon-unicons-desert:before {
  content: '\ea3a';
} /* '' */
.icon-unicons-desktop:before {
  content: '\ea3b';
} /* '' */
.icon-unicons-desktop-alt:before {
  content: '\ea3c';
} /* '' */
.icon-unicons-desktop-alt-slash:before {
  content: '\ea3d';
} /* '' */
.icon-unicons-desktop-cloud-alt:before {
  content: '\ea3e';
} /* '' */
.icon-unicons-desktop-slash:before {
  content: '\ea3f';
} /* '' */
.icon-unicons-dialpad:before {
  content: '\ea40';
} /* '' */
.icon-unicons-dialpad-alt:before {
  content: '\ea41';
} /* '' */
.icon-unicons-diamond:before {
  content: '\ea42';
} /* '' */
.icon-unicons-diary:before {
  content: '\ea43';
} /* '' */
.icon-unicons-diary-alt:before {
  content: '\ea44';
} /* '' */
.icon-unicons-dice-five:before {
  content: '\ea45';
} /* '' */
.icon-unicons-dice-four:before {
  content: '\ea46';
} /* '' */
.icon-unicons-dice-one:before {
  content: '\ea47';
} /* '' */
.icon-unicons-dice-six:before {
  content: '\ea48';
} /* '' */
.icon-unicons-dice-three:before {
  content: '\ea49';
} /* '' */
.icon-unicons-dice-two:before {
  content: '\ea4a';
} /* '' */
.icon-unicons-direction:before {
  content: '\ea4b';
} /* '' */
.icon-unicons-directions:before {
  content: '\ea4c';
} /* '' */
.icon-unicons-discord:before {
  content: '\ea4d';
} /* '' */
.icon-unicons-dizzy-meh:before {
  content: '\ea4e';
} /* '' */
.icon-unicons-dna:before {
  content: '\ea4f';
} /* '' */
.icon-unicons-docker:before {
  content: '\ea50';
} /* '' */
.icon-unicons-document-info:before {
  content: '\ea51';
} /* '' */
.icon-unicons-document-layout-center:before {
  content: '\ea52';
} /* '' */
.icon-unicons-document-layout-left:before {
  content: '\ea53';
} /* '' */
.icon-unicons-document-layout-right:before {
  content: '\ea54';
} /* '' */
.icon-unicons-dollar-alt:before {
  content: '\ea55';
} /* '' */
.icon-unicons-dollar-sign:before {
  content: '\ea56';
} /* '' */
.icon-unicons-dollar-sign-alt:before {
  content: '\ea57';
} /* '' */
.icon-unicons-download-alt:before {
  content: '\ea58';
} /* '' */
.icon-unicons-draggabledots:before {
  content: '\ea59';
} /* '' */
.icon-unicons-dribbble:before {
  content: '\ea5a';
} /* '' */
.icon-unicons-drill:before {
  content: '\ea5b';
} /* '' */
.icon-unicons-dropbox:before {
  content: '\ea5c';
} /* '' */
.icon-unicons-dumbbell:before {
  content: '\ea5d';
} /* '' */
.icon-unicons-ear:before {
  content: '\ea5e';
} /* '' */
.icon-unicons-edit:before {
  content: '\ea5f';
} /* '' */
.icon-unicons-edit-alt:before {
  content: '\ea60';
} /* '' */
.icon-unicons-elipsis-double-v-alt:before {
  content: '\ea61';
} /* '' */
.icon-unicons-ellipsis-h:before {
  content: '\ea62';
} /* '' */
.icon-unicons-ellipsis-v:before {
  content: '\ea63';
} /* '' */
.icon-unicons-emoji:before {
  content: '\ea64';
} /* '' */
.icon-unicons-english-to-chinese:before {
  content: '\ea65';
} /* '' */
.icon-unicons-enter:before {
  content: '\ea66';
} /* '' */
.icon-unicons-envelope:before {
  content: '\ea67';
} /* '' */
.icon-unicons-envelope-add:before {
  content: '\ea68';
} /* '' */
.icon-unicons-envelope-alt:before {
  content: '\ea69';
} /* '' */
.icon-unicons-envelope-block:before {
  content: '\ea6a';
} /* '' */
.icon-unicons-envelope-bookmark:before {
  content: '\ea6b';
} /* '' */
.icon-unicons-envelope-check:before {
  content: '\ea6c';
} /* '' */
.icon-unicons-envelope-download:before {
  content: '\ea6d';
} /* '' */
.icon-unicons-envelope-download-alt:before {
  content: '\ea6e';
} /* '' */
.icon-unicons-envelope-edit:before {
  content: '\ea6f';
} /* '' */
.icon-unicons-envelope-exclamation:before {
  content: '\ea70';
} /* '' */
.icon-unicons-envelope-heart:before {
  content: '\ea71';
} /* '' */
.icon-unicons-flip-v:before {
  content: '\ea72';
} /* '' */
.icon-unicons-flip-v-alt:before {
  content: '\ea73';
} /* '' */
.icon-unicons-flower:before {
  content: '\ea74';
} /* '' */
.icon-unicons-envelope-receive:before {
  content: '\ea75';
} /* '' */
.icon-unicons-envelope-redo:before {
  content: '\ea76';
} /* '' */
.icon-unicons-envelopes:before {
  content: '\ea77';
} /* '' */
.icon-unicons-envelope-search:before {
  content: '\ea78';
} /* '' */
.icon-unicons-envelope-send:before {
  content: '\ea79';
} /* '' */
.icon-unicons-envelope-share:before {
  content: '\ea7a';
} /* '' */
.icon-unicons-envelope-shield:before {
  content: '\ea7b';
} /* '' */
.icon-unicons-envelope-star:before {
  content: '\ea7c';
} /* '' */
.icon-unicons-envelope-times:before {
  content: '\ea7d';
} /* '' */
.icon-unicons-envelope-upload:before {
  content: '\ea7e';
} /* '' */
.icon-unicons-envelope-upload-alt:before {
  content: '\ea7f';
} /* '' */
.icon-unicons-equal-circle:before {
  content: '\ea80';
} /* '' */
.icon-unicons-estate:before {
  content: '\ea81';
} /* '' */
.icon-unicons-euro:before {
  content: '\ea82';
} /* '' */
.icon-unicons-euro-circle:before {
  content: '\ea83';
} /* '' */
.icon-unicons-exchange:before {
  content: '\ea84';
} /* '' */
.icon-unicons-exchange-alt:before {
  content: '\ea85';
} /* '' */
.icon-unicons-exclamation:before {
  content: '\ea86';
} /* '' */
.icon-unicons-exclamation-circle:before {
  content: '\ea87';
} /* '' */
.icon-unicons-exclamation-octagon:before {
  content: '\ea88';
} /* '' */
.icon-unicons-exclamation-triangle:before {
  content: '\ea89';
} /* '' */
.icon-unicons-exclude:before {
  content: '\ea8a';
} /* '' */
.icon-unicons-exit:before {
  content: '\ea8b';
} /* '' */
.icon-unicons-expand-alt:before {
  content: '\ea8c';
} /* '' */
.icon-unicons-expand-arrows:before {
  content: '\ea8d';
} /* '' */
.icon-unicons-expand-arrows-alt:before {
  content: '\ea8e';
} /* '' */
.icon-unicons-expand-from-corner:before {
  content: '\ea8f';
} /* '' */
.icon-unicons-expand-left:before {
  content: '\ea90';
} /* '' */
.icon-unicons-expand-right:before {
  content: '\ea91';
} /* '' */
.icon-unicons-export:before {
  content: '\ea92';
} /* '' */
.icon-unicons-exposure-alt:before {
  content: '\ea93';
} /* '' */
.icon-unicons-exposure-increase:before {
  content: '\ea94';
} /* '' */
.icon-unicons-external-link-alt:before {
  content: '\ea95';
} /* '' */
.icon-unicons-eye:before {
  content: '\ea96';
} /* '' */
.icon-unicons-eye-slash:before {
  content: '\ea97';
} /* '' */
.icon-unicons-facebook:before {
  content: '\ea98';
} /* '' */
.icon-unicons-facebook-f:before {
  content: '\ea99';
} /* '' */
.icon-unicons-facebook-messenger:before {
  content: '\ea9a';
} /* '' */
.icon-unicons-facebook-messenger-alt:before {
  content: '\ea9b';
} /* '' */
.icon-unicons-fahrenheit:before {
  content: '\ea9c';
} /* '' */
.icon-unicons-fast-mail:before {
  content: '\ea9d';
} /* '' */
.icon-unicons-fast-mail-alt:before {
  content: '\ea9e';
} /* '' */
.icon-unicons-favorite:before {
  content: '\ea9f';
} /* '' */
.icon-unicons-feedback:before {
  content: '\eaa0';
} /* '' */
.icon-unicons-fidget-spinner:before {
  content: '\eaa1';
} /* '' */
.icon-unicons-file:before {
  content: '\eaa2';
} /* '' */
.icon-unicons-file-alt:before {
  content: '\eaa3';
} /* '' */
.icon-unicons-file-blank:before {
  content: '\eaa4';
} /* '' */
.icon-unicons-file-block-alt:before {
  content: '\eaa5';
} /* '' */
.icon-unicons-file-bookmark-alt:before {
  content: '\eaa6';
} /* '' */
.icon-unicons-file-check:before {
  content: '\eaa7';
} /* '' */
.icon-unicons-file-check-alt:before {
  content: '\eaa8';
} /* '' */
.icon-unicons-file-contract:before {
  content: '\eaa9';
} /* '' */
.icon-unicons-file-contract-dollar:before {
  content: '\eaaa';
} /* '' */
.icon-unicons-file-copy-alt:before {
  content: '\eaab';
} /* '' */
.icon-unicons-file-download:before {
  content: '\eaac';
} /* '' */
.icon-unicons-file-download-alt:before {
  content: '\eaad';
} /* '' */
.icon-unicons-file-edit-alt:before {
  content: '\eaae';
} /* '' */
.icon-unicons-file-exclamation:before {
  content: '\eaaf';
} /* '' */
.icon-unicons-file-exclamation-alt:before {
  content: '\eab0';
} /* '' */
.icon-unicons-file-export:before {
  content: '\eab1';
} /* '' */
.icon-unicons-file-graph:before {
  content: '\eab2';
} /* '' */
.icon-unicons-file-heart:before {
  content: '\eab3';
} /* '' */
.icon-unicons-file-import:before {
  content: '\eab4';
} /* '' */
.icon-unicons-file-info-alt:before {
  content: '\eab5';
} /* '' */
.icon-unicons-file-landscape:before {
  content: '\eab6';
} /* '' */
.icon-unicons-file-landscape-alt:before {
  content: '\eab7';
} /* '' */
.icon-unicons-file-lanscape-slash:before {
  content: '\eab8';
} /* '' */
.icon-unicons-file-lock-alt:before {
  content: '\eab9';
} /* '' */
.icon-unicons-file-medical:before {
  content: '\eaba';
} /* '' */
.icon-unicons-file-medical-alt:before {
  content: '\eabb';
} /* '' */
.icon-unicons-file-minus:before {
  content: '\eabc';
} /* '' */
.icon-unicons-file-minus-alt:before {
  content: '\eabd';
} /* '' */
.icon-unicons-file-network:before {
  content: '\eabe';
} /* '' */
.icon-unicons-file-plus:before {
  content: '\eabf';
} /* '' */
.icon-unicons-file-plus-alt:before {
  content: '\eac0';
} /* '' */
.icon-unicons-file-question:before {
  content: '\eac1';
} /* '' */
.icon-unicons-file-question-alt:before {
  content: '\eac2';
} /* '' */
.icon-unicons-file-redo-alt:before {
  content: '\eac3';
} /* '' */
.icon-unicons-file-search-alt:before {
  content: '\eac4';
} /* '' */
.icon-unicons-file-share-alt:before {
  content: '\eac5';
} /* '' */
.icon-unicons-file-shield-alt:before {
  content: '\eac6';
} /* '' */
.icon-unicons-files-landscapes:before {
  content: '\eac7';
} /* '' */
.icon-unicons-files-landscapes-alt:before {
  content: '\eac8';
} /* '' */
.icon-unicons-file-slash:before {
  content: '\eac9';
} /* '' */
.icon-unicons-file-times:before {
  content: '\eaca';
} /* '' */
.icon-unicons-file-times-alt:before {
  content: '\eacb';
} /* '' */
.icon-unicons-file-upload:before {
  content: '\eacc';
} /* '' */
.icon-unicons-file-upload-alt:before {
  content: '\eacd';
} /* '' */
.icon-unicons-film:before {
  content: '\eace';
} /* '' */
.icon-unicons-filter:before {
  content: '\eacf';
} /* '' */
.icon-unicons-filter-slash:before {
  content: '\ead0';
} /* '' */
.icon-unicons-fire:before {
  content: '\ead1';
} /* '' */
.icon-unicons-flask:before {
  content: '\ead2';
} /* '' */
.icon-unicons-flask-potion:before {
  content: '\ead3';
} /* '' */
.icon-unicons-flip-h:before {
  content: '\ead4';
} /* '' */
.icon-unicons-flip-h-alt:before {
  content: '\ead5';
} /* '' */
.icon-unicons-image-download:before {
  content: '\ead6';
} /* '' */
.icon-unicons-image-edit:before {
  content: '\ead7';
} /* '' */
.icon-unicons-focus:before {
  content: '\ead8';
} /* '' */
.icon-unicons-focus-add:before {
  content: '\ead9';
} /* '' */
.icon-unicons-focus-target:before {
  content: '\eada';
} /* '' */
.icon-unicons-folder:before {
  content: '\eadb';
} /* '' */
.icon-unicons-folder-check:before {
  content: '\eadc';
} /* '' */
.icon-unicons-folder-download:before {
  content: '\eadd';
} /* '' */
.icon-unicons-folder-exclamation:before {
  content: '\eade';
} /* '' */
.icon-unicons-folder-heart:before {
  content: '\eadf';
} /* '' */
.icon-unicons-folder-info:before {
  content: '\eae0';
} /* '' */
.icon-unicons-folder-lock:before {
  content: '\eae1';
} /* '' */
.icon-unicons-folder-medical:before {
  content: '\eae2';
} /* '' */
.icon-unicons-folder-minus:before {
  content: '\eae3';
} /* '' */
.icon-unicons-folder-network:before {
  content: '\eae4';
} /* '' */
.icon-unicons-folder-open:before {
  content: '\eae5';
} /* '' */
.icon-unicons-folder-plus:before {
  content: '\eae6';
} /* '' */
.icon-unicons-folder-question:before {
  content: '\eae7';
} /* '' */
.icon-unicons-folder-slash:before {
  content: '\eae8';
} /* '' */
.icon-unicons-folder-times:before {
  content: '\eae9';
} /* '' */
.icon-unicons-folder-upload:before {
  content: '\eaea';
} /* '' */
.icon-unicons-font:before {
  content: '\eaeb';
} /* '' */
.icon-unicons-football:before {
  content: '\eaec';
} /* '' */
.icon-unicons-football-american:before {
  content: '\eaed';
} /* '' */
.icon-unicons-football-ball:before {
  content: '\eaee';
} /* '' */
.icon-unicons-forecastcloud-moon-tear:before {
  content: '\eaef';
} /* '' */
.icon-unicons-forwaded-call:before {
  content: '\eaf0';
} /* '' */
.icon-unicons-forward:before {
  content: '\eaf1';
} /* '' */
.icon-unicons-frown:before {
  content: '\eaf2';
} /* '' */
.icon-unicons-game-structure:before {
  content: '\eaf3';
} /* '' */
.icon-unicons-gift:before {
  content: '\eaf4';
} /* '' */
.icon-unicons-github:before {
  content: '\eaf5';
} /* '' */
.icon-unicons-github-alt:before {
  content: '\eaf6';
} /* '' */
.icon-unicons-gitlab:before {
  content: '\eaf7';
} /* '' */
.icon-unicons-glass:before {
  content: '\eaf8';
} /* '' */
.icon-unicons-glass-martini:before {
  content: '\eaf9';
} /* '' */
.icon-unicons-glass-martini-alt:before {
  content: '\eafa';
} /* '' */
.icon-unicons-glass-martini-alt-slash:before {
  content: '\eafb';
} /* '' */
.icon-unicons-glass-tea:before {
  content: '\eafc';
} /* '' */
.icon-unicons-globe:before {
  content: '\eafd';
} /* '' */
.icon-unicons-gold:before {
  content: '\eafe';
} /* '' */
.icon-unicons-golf-ball:before {
  content: '\eaff';
} /* '' */
.icon-unicons-google:before {
  content: '\eb00';
} /* '' */
.icon-unicons-google-drive:before {
  content: '\eb01';
} /* '' */
.icon-unicons-google-drive-alt:before {
  content: '\eb02';
} /* '' */
.icon-unicons-google-hangouts:before {
  content: '\eb03';
} /* '' */
.icon-unicons-google-hangouts-alt:before {
  content: '\eb04';
} /* '' */
.icon-unicons-google-play:before {
  content: '\eb05';
} /* '' */
.icon-unicons-graduation-cap:before {
  content: '\eb06';
} /* '' */
.icon-unicons-graph-bar:before {
  content: '\eb07';
} /* '' */
.icon-unicons-grid:before {
  content: '\eb08';
} /* '' */
.icon-unicons-grids:before {
  content: '\eb09';
} /* '' */
.icon-unicons-grin:before {
  content: '\eb0a';
} /* '' */
.icon-unicons-grin-tongue-wink:before {
  content: '\eb0b';
} /* '' */
.icon-unicons-grin-tongue-wink-alt:before {
  content: '\eb0c';
} /* '' */
.icon-unicons-grip-horizontal-line:before {
  content: '\eb0d';
} /* '' */
.icon-unicons-hard-hat:before {
  content: '\eb0e';
} /* '' */
.icon-unicons-hdd:before {
  content: '\eb0f';
} /* '' */
.icon-unicons-headphones:before {
  content: '\eb10';
} /* '' */
.icon-unicons-headphones-alt:before {
  content: '\eb11';
} /* '' */
.icon-unicons-headphone-slash:before {
  content: '\eb12';
} /* '' */
.icon-unicons-head-side:before {
  content: '\eb13';
} /* '' */
.icon-unicons-head-side-cough:before {
  content: '\eb14';
} /* '' */
.icon-unicons-head-side-mask:before {
  content: '\eb15';
} /* '' */
.icon-unicons-heart:before {
  content: '\eb16';
} /* '' */
.icon-unicons-heart-alt:before {
  content: '\eb17';
} /* '' */
.icon-unicons-heartbeat:before {
  content: '\eb18';
} /* '' */
.icon-unicons-heart-break:before {
  content: '\eb19';
} /* '' */
.icon-unicons-heart-medical:before {
  content: '\eb1a';
} /* '' */
.icon-unicons-heart-rate:before {
  content: '\eb1b';
} /* '' */
.icon-unicons-heart-sign:before {
  content: '\eb1c';
} /* '' */
.icon-unicons-hindi-to-chinese:before {
  content: '\eb1d';
} /* '' */
.icon-unicons-hipchat:before {
  content: '\eb1e';
} /* '' */
.icon-unicons-history:before {
  content: '\eb1f';
} /* '' */
.icon-unicons-history-alt:before {
  content: '\eb20';
} /* '' */
.icon-unicons-home:before {
  content: '\eb21';
} /* '' */
.icon-unicons-home-alt:before {
  content: '\eb22';
} /* '' */
.icon-unicons-horizontal-align-center:before {
  content: '\eb23';
} /* '' */
.icon-unicons-horizontal-align-left:before {
  content: '\eb24';
} /* '' */
.icon-unicons-horizontal-align-right:before {
  content: '\eb25';
} /* '' */
.icon-unicons-horizontal-distribution-center:before {
  content: '\eb26';
} /* '' */
.icon-unicons-horizontal-distribution-left:before {
  content: '\eb27';
} /* '' */
.icon-unicons-horizontal-distribution-right:before {
  content: '\eb28';
} /* '' */
.icon-unicons-hospital:before {
  content: '\eb29';
} /* '' */
.icon-unicons-hospital-square-sign:before {
  content: '\eb2a';
} /* '' */
.icon-unicons-hospital-symbol:before {
  content: '\eb2b';
} /* '' */
.icon-unicons-hourglass:before {
  content: '\eb2c';
} /* '' */
.icon-unicons-house-user:before {
  content: '\eb2d';
} /* '' */
.icon-unicons-html3:before {
  content: '\eb2e';
} /* '' */
.icon-unicons-html3-alt:before {
  content: '\eb2f';
} /* '' */
.icon-unicons-html5:before {
  content: '\eb30';
} /* '' */
.icon-unicons-html5-alt:before {
  content: '\eb31';
} /* '' */
.icon-unicons-hunting:before {
  content: '\eb32';
} /* '' */
.icon-unicons-icons:before {
  content: '\eb33';
} /* '' */
.icon-unicons-illustration:before {
  content: '\eb34';
} /* '' */
.icon-unicons-image:before {
  content: '\eb35';
} /* '' */
.icon-unicons-image-alt-slash:before {
  content: '\eb36';
} /* '' */
.icon-unicons-image-block:before {
  content: '\eb37';
} /* '' */
.icon-unicons-image-broken:before {
  content: '\eb38';
} /* '' */
.icon-unicons-image-check:before {
  content: '\eb39';
} /* '' */
.icon-unicons-map-marker-edit:before {
  content: '\eb3a';
} /* '' */
.icon-unicons-map-marker-info:before {
  content: '\eb3b';
} /* '' */
.icon-unicons-map-marker-minus:before {
  content: '\eb3c';
} /* '' */
.icon-unicons-image-lock:before {
  content: '\eb3d';
} /* '' */
.icon-unicons-image-minus:before {
  content: '\eb3e';
} /* '' */
.icon-unicons-image-plus:before {
  content: '\eb3f';
} /* '' */
.icon-unicons-image-question:before {
  content: '\eb40';
} /* '' */
.icon-unicons-image-redo:before {
  content: '\eb41';
} /* '' */
.icon-unicons-image-resize-landscape:before {
  content: '\eb42';
} /* '' */
.icon-unicons-image-resize-square:before {
  content: '\eb43';
} /* '' */
.icon-unicons-images:before {
  content: '\eb44';
} /* '' */
.icon-unicons-image-search:before {
  content: '\eb45';
} /* '' */
.icon-unicons-image-share:before {
  content: '\eb46';
} /* '' */
.icon-unicons-image-shield:before {
  content: '\eb47';
} /* '' */
.icon-unicons-image-slash:before {
  content: '\eb48';
} /* '' */
.icon-unicons-image-times:before {
  content: '\eb49';
} /* '' */
.icon-unicons-image-upload:before {
  content: '\eb4a';
} /* '' */
.icon-unicons-image-v:before {
  content: '\eb4b';
} /* '' */
.icon-unicons-import:before {
  content: '\eb4c';
} /* '' */
.icon-unicons-inbox:before {
  content: '\eb4d';
} /* '' */
.icon-unicons-incoming-call:before {
  content: '\eb4e';
} /* '' */
.icon-unicons-info:before {
  content: '\eb4f';
} /* '' */
.icon-unicons-info-circle:before {
  content: '\eb50';
} /* '' */
.icon-unicons-instagram:before {
  content: '\eb51';
} /* '' */
.icon-unicons-instagram-alt:before {
  content: '\eb52';
} /* '' */
.icon-unicons-intercom:before {
  content: '\eb53';
} /* '' */
.icon-unicons-intercom-alt:before {
  content: '\eb54';
} /* '' */
.icon-unicons-invoice:before {
  content: '\eb55';
} /* '' */
.icon-unicons-italic:before {
  content: '\eb56';
} /* '' */
.icon-unicons-jackhammer:before {
  content: '\eb57';
} /* '' */
.icon-unicons-java-script:before {
  content: '\eb58';
} /* '' */
.icon-unicons-kayak:before {
  content: '\eb59';
} /* '' */
.icon-unicons-keyboard:before {
  content: '\eb5a';
} /* '' */
.icon-unicons-keyboard-alt:before {
  content: '\eb5b';
} /* '' */
.icon-unicons-keyboard-hide:before {
  content: '\eb5c';
} /* '' */
.icon-unicons-keyboard-show:before {
  content: '\eb5d';
} /* '' */
.icon-unicons-keyhole-circle:before {
  content: '\eb5e';
} /* '' */
.icon-unicons-keyhole-square:before {
  content: '\eb5f';
} /* '' */
.icon-unicons-keyhole-square-full:before {
  content: '\eb60';
} /* '' */
.icon-unicons-key-skeleton:before {
  content: '\eb61';
} /* '' */
.icon-unicons-key-skeleton-alt:before {
  content: '\eb62';
} /* '' */
.icon-unicons-kid:before {
  content: '\eb63';
} /* '' */
.icon-unicons-label:before {
  content: '\eb64';
} /* '' */
.icon-unicons-label-alt:before {
  content: '\eb65';
} /* '' */
.icon-unicons-lamp:before {
  content: '\eb66';
} /* '' */
.icon-unicons-language:before {
  content: '\eb67';
} /* '' */
.icon-unicons-laptop:before {
  content: '\eb68';
} /* '' */
.icon-unicons-laptop-cloud:before {
  content: '\eb69';
} /* '' */
.icon-unicons-laptop-connection:before {
  content: '\eb6a';
} /* '' */
.icon-unicons-laughing:before {
  content: '\eb6b';
} /* '' */
.icon-unicons-layer-group:before {
  content: '\eb6c';
} /* '' */
.icon-unicons-layer-group-slash:before {
  content: '\eb6d';
} /* '' */
.icon-unicons-layers:before {
  content: '\eb6e';
} /* '' */
.icon-unicons-layers-alt:before {
  content: '\eb6f';
} /* '' */
.icon-unicons-layers-slash:before {
  content: '\eb70';
} /* '' */
.icon-unicons-left-arrow-from-left:before {
  content: '\eb71';
} /* '' */
.icon-unicons-left-arrow-to-left:before {
  content: '\eb72';
} /* '' */
.icon-unicons-left-indent:before {
  content: '\eb73';
} /* '' */
.icon-unicons-left-indent-alt:before {
  content: '\eb74';
} /* '' */
.icon-unicons-left-to-right-text-direction:before {
  content: '\eb75';
} /* '' */
.icon-unicons-letter-chinese-a:before {
  content: '\eb76';
} /* '' */
.icon-unicons-letter-english-a:before {
  content: '\eb77';
} /* '' */
.icon-unicons-letter-hindi-a:before {
  content: '\eb78';
} /* '' */
.icon-unicons-letter-japanese-a:before {
  content: '\eb79';
} /* '' */
.icon-unicons-life-ring:before {
  content: '\eb7a';
} /* '' */
.icon-unicons-lightbulb:before {
  content: '\eb7b';
} /* '' */
.icon-unicons-lightbulb-alt:before {
  content: '\eb7c';
} /* '' */
.icon-unicons-line:before {
  content: '\eb7d';
} /* '' */
.icon-unicons-line-alt:before {
  content: '\eb7e';
} /* '' */
.icon-unicons-line-spacing:before {
  content: '\eb7f';
} /* '' */
.icon-unicons-link:before {
  content: '\eb80';
} /* '' */
.icon-unicons-link-add:before {
  content: '\eb81';
} /* '' */
.icon-unicons-link-alt:before {
  content: '\eb82';
} /* '' */
.icon-unicons-link-broken:before {
  content: '\eb83';
} /* '' */
.icon-unicons-linkedin:before {
  content: '\eb84';
} /* '' */
.icon-unicons-linkedin-alt:before {
  content: '\eb85';
} /* '' */
.icon-unicons-link-h:before {
  content: '\eb86';
} /* '' */
.icon-unicons-linux:before {
  content: '\eb87';
} /* '' */
.icon-unicons-lira-sign:before {
  content: '\eb88';
} /* '' */
.icon-unicons-list-ol:before {
  content: '\eb89';
} /* '' */
.icon-unicons-list-ol-alt:before {
  content: '\eb8a';
} /* '' */
.icon-unicons-list-ui-alt:before {
  content: '\eb8b';
} /* '' */
.icon-unicons-list-ul:before {
  content: '\eb8c';
} /* '' */
.icon-unicons-location-arrow:before {
  content: '\eb8d';
} /* '' */
.icon-unicons-location-arrow-alt:before {
  content: '\eb8e';
} /* '' */
.icon-unicons-location-pin-alt:before {
  content: '\eb8f';
} /* '' */
.icon-unicons-location-point:before {
  content: '\eb90';
} /* '' */
.icon-unicons-lock:before {
  content: '\eb91';
} /* '' */
.icon-unicons-lock-access:before {
  content: '\eb92';
} /* '' */
.icon-unicons-lock-alt:before {
  content: '\eb93';
} /* '' */
.icon-unicons-lock-open-alt:before {
  content: '\eb94';
} /* '' */
.icon-unicons-lock-slash:before {
  content: '\eb95';
} /* '' */
.icon-unicons-lottiefiles:before {
  content: '\eb96';
} /* '' */
.icon-unicons-lottiefiles-alt:before {
  content: '\eb97';
} /* '' */
.icon-unicons-luggage-cart:before {
  content: '\eb98';
} /* '' */
.icon-unicons-mailbox:before {
  content: '\eb99';
} /* '' */
.icon-unicons-mailbox-alt:before {
  content: '\eb9a';
} /* '' */
.icon-unicons-map:before {
  content: '\eb9b';
} /* '' */
.icon-unicons-map-marker:before {
  content: '\eb9c';
} /* '' */
.icon-unicons-map-marker-alt:before {
  content: '\eb9d';
} /* '' */
.icon-unicons-phone:before {
  content: '\eb9e';
} /* '' */
.icon-unicons-phone-alt:before {
  content: '\eb9f';
} /* '' */
.icon-unicons-phone-pause:before {
  content: '\eba0';
} /* '' */
.icon-unicons-map-marker-plus:before {
  content: '\eba1';
} /* '' */
.icon-unicons-map-marker-question:before {
  content: '\eba2';
} /* '' */
.icon-unicons-map-marker-shield:before {
  content: '\eba3';
} /* '' */
.icon-unicons-map-marker-slash:before {
  content: '\eba4';
} /* '' */
.icon-unicons-map-pin:before {
  content: '\eba5';
} /* '' */
.icon-unicons-map-pin-alt:before {
  content: '\eba6';
} /* '' */
.icon-unicons-mars:before {
  content: '\eba7';
} /* '' */
.icon-unicons-master-card:before {
  content: '\eba8';
} /* '' */
.icon-unicons-maximize-left:before {
  content: '\eba9';
} /* '' */
.icon-unicons-medal:before {
  content: '\ebaa';
} /* '' */
.icon-unicons-medical-drip:before {
  content: '\ebab';
} /* '' */
.icon-unicons-medical-square:before {
  content: '\ebac';
} /* '' */
.icon-unicons-medical-square-full:before {
  content: '\ebad';
} /* '' */
.icon-unicons-medium-m:before {
  content: '\ebae';
} /* '' */
.icon-unicons-medkit:before {
  content: '\ebaf';
} /* '' */
.icon-unicons-meeting-board:before {
  content: '\ebb0';
} /* '' */
.icon-unicons-megaphone:before {
  content: '\ebb1';
} /* '' */
.icon-unicons-meh:before {
  content: '\ebb2';
} /* '' */
.icon-unicons-meh-alt:before {
  content: '\ebb3';
} /* '' */
.icon-unicons-meh-closed-eye:before {
  content: '\ebb4';
} /* '' */
.icon-unicons-message:before {
  content: '\ebb5';
} /* '' */
.icon-unicons-metro:before {
  content: '\ebb6';
} /* '' */
.icon-unicons-microphone:before {
  content: '\ebb7';
} /* '' */
.icon-unicons-microphone-slash:before {
  content: '\ebb8';
} /* '' */
.icon-unicons-microscope:before {
  content: '\ebb9';
} /* '' */
.icon-unicons-microsoft:before {
  content: '\ebba';
} /* '' */
.icon-unicons-minus:before {
  content: '\ebbb';
} /* '' */
.icon-unicons-minus-circle:before {
  content: '\ebbc';
} /* '' */
.icon-unicons-minus-path:before {
  content: '\ebbd';
} /* '' */
.icon-unicons-minus-square:before {
  content: '\ebbe';
} /* '' */
.icon-unicons-minus-square-full:before {
  content: '\ebbf';
} /* '' */
.icon-unicons-missed-call:before {
  content: '\ebc0';
} /* '' */
.icon-unicons-mobile-android:before {
  content: '\ebc1';
} /* '' */
.icon-unicons-mobile-android-alt:before {
  content: '\ebc2';
} /* '' */
.icon-unicons-mobile-vibrate:before {
  content: '\ebc3';
} /* '' */
.icon-unicons-modem:before {
  content: '\ebc4';
} /* '' */
.icon-unicons-moneybag:before {
  content: '\ebc5';
} /* '' */
.icon-unicons-moneybag-alt:before {
  content: '\ebc6';
} /* '' */
.icon-unicons-money-bill:before {
  content: '\ebc7';
} /* '' */
.icon-unicons-money-bill-slash:before {
  content: '\ebc8';
} /* '' */
.icon-unicons-money-bill-stack:before {
  content: '\ebc9';
} /* '' */
.icon-unicons-money-insert:before {
  content: '\ebca';
} /* '' */
.icon-unicons-money-stack:before {
  content: '\ebcb';
} /* '' */
.icon-unicons-money-withdraw:before {
  content: '\ebcc';
} /* '' */
.icon-unicons-money-withdrawal:before {
  content: '\ebcd';
} /* '' */
.icon-unicons-monitor:before {
  content: '\ebce';
} /* '' */
.icon-unicons-monitor-heart-rate:before {
  content: '\ebcf';
} /* '' */
.icon-unicons-moon:before {
  content: '\ebd0';
} /* '' */
.icon-unicons-moon-eclipse:before {
  content: '\ebd1';
} /* '' */
.icon-unicons-moonset:before {
  content: '\ebd2';
} /* '' */
.icon-unicons-mountains:before {
  content: '\ebd3';
} /* '' */
.icon-unicons-mountains-sun:before {
  content: '\ebd4';
} /* '' */
.icon-unicons-mouse:before {
  content: '\ebd5';
} /* '' */
.icon-unicons-mouse-alt:before {
  content: '\ebd6';
} /* '' */
.icon-unicons-mouse-alt-2:before {
  content: '\ebd7';
} /* '' */
.icon-unicons-multiply:before {
  content: '\ebd8';
} /* '' */
.icon-unicons-music:before {
  content: '\ebd9';
} /* '' */
.icon-unicons-music-note:before {
  content: '\ebda';
} /* '' */
.icon-unicons-music-tune-slash:before {
  content: '\ebdb';
} /* '' */
.icon-unicons-n-a:before {
  content: '\ebdc';
} /* '' */
.icon-unicons-navigator:before {
  content: '\ebdd';
} /* '' */
.icon-unicons-nerd:before {
  content: '\ebde';
} /* '' */
.icon-unicons-newspaper:before {
  content: '\ebdf';
} /* '' */
.icon-unicons-ninja:before {
  content: '\ebe0';
} /* '' */
.icon-unicons-no-entry:before {
  content: '\ebe1';
} /* '' */
.icon-unicons-notebooks:before {
  content: '\ebe2';
} /* '' */
.icon-unicons-notes:before {
  content: '\ebe3';
} /* '' */
.icon-unicons-object-group:before {
  content: '\ebe4';
} /* '' */
.icon-unicons-object-ungroup:before {
  content: '\ebe5';
} /* '' */
.icon-unicons-octagon:before {
  content: '\ebe6';
} /* '' */
.icon-unicons-okta:before {
  content: '\ebe7';
} /* '' */
.icon-unicons-opera:before {
  content: '\ebe8';
} /* '' */
.icon-unicons-opera-alt:before {
  content: '\ebe9';
} /* '' */
.icon-unicons-outgoing-call:before {
  content: '\ebea';
} /* '' */
.icon-unicons-package:before {
  content: '\ebeb';
} /* '' */
.icon-unicons-padlock:before {
  content: '\ebec';
} /* '' */
.icon-unicons-pagelines:before {
  content: '\ebed';
} /* '' */
.icon-unicons-pagerduty:before {
  content: '\ebee';
} /* '' */
.icon-unicons-paint-tool:before {
  content: '\ebef';
} /* '' */
.icon-unicons-palette:before {
  content: '\ebf0';
} /* '' */
.icon-unicons-panel-add:before {
  content: '\ebf1';
} /* '' */
.icon-unicons-panorama-h:before {
  content: '\ebf2';
} /* '' */
.icon-unicons-panorama-h-alt:before {
  content: '\ebf3';
} /* '' */
.icon-unicons-panorama-v:before {
  content: '\ebf4';
} /* '' */
.icon-unicons-paperclip:before {
  content: '\ebf5';
} /* '' */
.icon-unicons-paragraph:before {
  content: '\ebf6';
} /* '' */
.icon-unicons-parcel:before {
  content: '\ebf7';
} /* '' */
.icon-unicons-parking-circle:before {
  content: '\ebf8';
} /* '' */
.icon-unicons-parking-square:before {
  content: '\ebf9';
} /* '' */
.icon-unicons-pathfinder:before {
  content: '\ebfa';
} /* '' */
.icon-unicons-pathfinder-unite:before {
  content: '\ebfb';
} /* '' */
.icon-unicons-pause:before {
  content: '\ebfc';
} /* '' */
.icon-unicons-pause-circle:before {
  content: '\ebfd';
} /* '' */
.icon-unicons-paypal:before {
  content: '\ebfe';
} /* '' */
.icon-unicons-pen:before {
  content: '\ebff';
} /* '' */
.icon-unicons-pentagon:before {
  content: '\ec00';
} /* '' */
.icon-unicons-percentage:before {
  content: '\ec01';
} /* '' */
.icon-unicons-setting:before {
  content: '\ec02';
} /* '' */
.icon-unicons-share:before {
  content: '\ec03';
} /* '' */
.icon-unicons-share-alt:before {
  content: '\ec04';
} /* '' */
.icon-unicons-shield:before {
  content: '\ec05';
} /* '' */
.icon-unicons-phone-slash:before {
  content: '\ec06';
} /* '' */
.icon-unicons-phone-times:before {
  content: '\ec07';
} /* '' */
.icon-unicons-phone-volume:before {
  content: '\ec08';
} /* '' */
.icon-unicons-picture:before {
  content: '\ec09';
} /* '' */
.icon-unicons-pizza-slice:before {
  content: '\ec0a';
} /* '' */
.icon-unicons-plane:before {
  content: '\ec0b';
} /* '' */
.icon-unicons-plane-arrival:before {
  content: '\ec0c';
} /* '' */
.icon-unicons-plane-departure:before {
  content: '\ec0d';
} /* '' */
.icon-unicons-plane-fly:before {
  content: '\ec0e';
} /* '' */
.icon-unicons-play:before {
  content: '\ec0f';
} /* '' */
.icon-unicons-play-circle:before {
  content: '\ec10';
} /* '' */
.icon-unicons-plug:before {
  content: '\ec11';
} /* '' */
.icon-unicons-plus:before {
  content: '\ec12';
} /* '' */
.icon-unicons-plus-circle:before {
  content: '\ec13';
} /* '' */
.icon-unicons-plus-square:before {
  content: '\ec14';
} /* '' */
.icon-unicons-podium:before {
  content: '\ec15';
} /* '' */
.icon-unicons-polygon:before {
  content: '\ec16';
} /* '' */
.icon-unicons-postcard:before {
  content: '\ec17';
} /* '' */
.icon-unicons-post-stamp:before {
  content: '\ec18';
} /* '' */
.icon-unicons-pound:before {
  content: '\ec19';
} /* '' */
.icon-unicons-pound-circle:before {
  content: '\ec1a';
} /* '' */
.icon-unicons-power:before {
  content: '\ec1b';
} /* '' */
.icon-unicons-prescription-bottle:before {
  content: '\ec1c';
} /* '' */
.icon-unicons-presentation:before {
  content: '\ec1d';
} /* '' */
.icon-unicons-presentation-check:before {
  content: '\ec1e';
} /* '' */
.icon-unicons-presentation-edit:before {
  content: '\ec1f';
} /* '' */
.icon-unicons-presentation-line:before {
  content: '\ec20';
} /* '' */
.icon-unicons-presentation-lines-alt:before {
  content: '\ec21';
} /* '' */
.icon-unicons-presentation-minus:before {
  content: '\ec22';
} /* '' */
.icon-unicons-presentation-play:before {
  content: '\ec23';
} /* '' */
.icon-unicons-presentation-plus:before {
  content: '\ec24';
} /* '' */
.icon-unicons-presentation-times:before {
  content: '\ec25';
} /* '' */
.icon-unicons-previous:before {
  content: '\ec26';
} /* '' */
.icon-unicons-pricetag-alt:before {
  content: '\ec27';
} /* '' */
.icon-unicons-print:before {
  content: '\ec28';
} /* '' */
.icon-unicons-print-slash:before {
  content: '\ec29';
} /* '' */
.icon-unicons-process:before {
  content: '\ec2a';
} /* '' */
.icon-unicons-processor:before {
  content: '\ec2b';
} /* '' */
.icon-unicons-programming-language:before {
  content: '\ec2c';
} /* '' */
.icon-unicons-pump:before {
  content: '\ec2d';
} /* '' */
.icon-unicons-puzzle-piece:before {
  content: '\ec2e';
} /* '' */
.icon-unicons-qrcode-scan:before {
  content: '\ec2f';
} /* '' */
.icon-unicons-question:before {
  content: '\ec30';
} /* '' */
.icon-unicons-question-circle:before {
  content: '\ec31';
} /* '' */
.icon-unicons-rainbow:before {
  content: '\ec32';
} /* '' */
.icon-unicons-raindrops:before {
  content: '\ec33';
} /* '' */
.icon-unicons-raindrops-alt:before {
  content: '\ec34';
} /* '' */
.icon-unicons-react:before {
  content: '\ec35';
} /* '' */
.icon-unicons-receipt:before {
  content: '\ec36';
} /* '' */
.icon-unicons-receipt-alt:before {
  content: '\ec37';
} /* '' */
.icon-unicons-record-audio:before {
  content: '\ec38';
} /* '' */
.icon-unicons-reddit-alien-alt:before {
  content: '\ec39';
} /* '' */
.icon-unicons-redo:before {
  content: '\ec3a';
} /* '' */
.icon-unicons-refresh:before {
  content: '\ec3b';
} /* '' */
.icon-unicons-registered:before {
  content: '\ec3c';
} /* '' */
.icon-unicons-repeat:before {
  content: '\ec3d';
} /* '' */
.icon-unicons-restaurant:before {
  content: '\ec3e';
} /* '' */
.icon-unicons-right-indent-alt:before {
  content: '\ec3f';
} /* '' */
.icon-unicons-right-to-left-text-direction:before {
  content: '\ec40';
} /* '' */
.icon-unicons-robot:before {
  content: '\ec41';
} /* '' */
.icon-unicons-rocket:before {
  content: '\ec42';
} /* '' */
.icon-unicons-rope-way:before {
  content: '\ec43';
} /* '' */
.icon-unicons-rotate-360:before {
  content: '\ec44';
} /* '' */
.icon-unicons-rss:before {
  content: '\ec45';
} /* '' */
.icon-unicons-rss-alt:before {
  content: '\ec46';
} /* '' */
.icon-unicons-rss-interface:before {
  content: '\ec47';
} /* '' */
.icon-unicons-ruler:before {
  content: '\ec48';
} /* '' */
.icon-unicons-ruler-combined:before {
  content: '\ec49';
} /* '' */
.icon-unicons-rupee-sign:before {
  content: '\ec4a';
} /* '' */
.icon-unicons-sad:before {
  content: '\ec4b';
} /* '' */
.icon-unicons-sad-cry:before {
  content: '\ec4c';
} /* '' */
.icon-unicons-sad-crying:before {
  content: '\ec4d';
} /* '' */
.icon-unicons-sad-dizzy:before {
  content: '\ec4e';
} /* '' */
.icon-unicons-sad-squint:before {
  content: '\ec4f';
} /* '' */
.icon-unicons-sanitizer:before {
  content: '\ec50';
} /* '' */
.icon-unicons-sanitizer-alt:before {
  content: '\ec51';
} /* '' */
.icon-unicons-save:before {
  content: '\ec52';
} /* '' */
.icon-unicons-scaling-left:before {
  content: '\ec53';
} /* '' */
.icon-unicons-scaling-right:before {
  content: '\ec54';
} /* '' */
.icon-unicons-scenery:before {
  content: '\ec55';
} /* '' */
.icon-unicons-schedule:before {
  content: '\ec56';
} /* '' */
.icon-unicons-screw:before {
  content: '\ec57';
} /* '' */
.icon-unicons-scroll:before {
  content: '\ec58';
} /* '' */
.icon-unicons-scroll-h:before {
  content: '\ec59';
} /* '' */
.icon-unicons-search:before {
  content: '\ec5a';
} /* '' */
.icon-unicons-search-alt:before {
  content: '\ec5b';
} /* '' */
.icon-unicons-search-minus:before {
  content: '\ec5c';
} /* '' */
.icon-unicons-search-plus:before {
  content: '\ec5d';
} /* '' */
.icon-unicons-selfie:before {
  content: '\ec5e';
} /* '' */
.icon-unicons-server:before {
  content: '\ec5f';
} /* '' */
.icon-unicons-server-alt:before {
  content: '\ec60';
} /* '' */
.icon-unicons-server-connection:before {
  content: '\ec61';
} /* '' */
.icon-unicons-server-network:before {
  content: '\ec62';
} /* '' */
.icon-unicons-server-network-alt:before {
  content: '\ec63';
} /* '' */
.icon-unicons-servers:before {
  content: '\ec64';
} /* '' */
.icon-unicons-servicemark:before {
  content: '\ec65';
} /* '' */
.icon-unicons-sync:before {
  content: '\ec66';
} /* '' */
.icon-unicons-sync-exclamation:before {
  content: '\ec67';
} /* '' */
.icon-unicons-shield-check:before {
  content: '\ec68';
} /* '' */
.icon-unicons-shield-exclamation:before {
  content: '\ec69';
} /* '' */
.icon-unicons-shield-plus:before {
  content: '\ec6a';
} /* '' */
.icon-unicons-shield-question:before {
  content: '\ec6b';
} /* '' */
.icon-unicons-shield-slash:before {
  content: '\ec6c';
} /* '' */
.icon-unicons-ship:before {
  content: '\ec6d';
} /* '' */
.icon-unicons-shop:before {
  content: '\ec6e';
} /* '' */
.icon-unicons-shopping-bag:before {
  content: '\ec6f';
} /* '' */
.icon-unicons-shopping-basket:before {
  content: '\ec70';
} /* '' */
.icon-unicons-shopping-cart:before {
  content: '\ec71';
} /* '' */
.icon-unicons-shopping-cart-alt:before {
  content: '\ec72';
} /* '' */
.icon-unicons-shovel:before {
  content: '\ec73';
} /* '' */
.icon-unicons-shrink:before {
  content: '\ec74';
} /* '' */
.icon-unicons-shuffle:before {
  content: '\ec75';
} /* '' */
.icon-unicons-shutter:before {
  content: '\ec76';
} /* '' */
.icon-unicons-shutter-alt:before {
  content: '\ec77';
} /* '' */
.icon-unicons-sick:before {
  content: '\ec78';
} /* '' */
.icon-unicons-sigma:before {
  content: '\ec79';
} /* '' */
.icon-unicons-signal:before {
  content: '\ec7a';
} /* '' */
.icon-unicons-signal-alt:before {
  content: '\ec7b';
} /* '' */
.icon-unicons-signal-alt-3:before {
  content: '\ec7c';
} /* '' */
.icon-unicons-sign-alt:before {
  content: '\ec7d';
} /* '' */
.icon-unicons-signin:before {
  content: '\ec7e';
} /* '' */
.icon-unicons-sign-in-alt:before {
  content: '\ec7f';
} /* '' */
.icon-unicons-sign-left:before {
  content: '\ec80';
} /* '' */
.icon-unicons-signout:before {
  content: '\ec81';
} /* '' */
.icon-unicons-sign-out-alt:before {
  content: '\ec82';
} /* '' */
.icon-unicons-sign-right:before {
  content: '\ec83';
} /* '' */
.icon-unicons-silence:before {
  content: '\ec84';
} /* '' */
.icon-unicons-silent-squint:before {
  content: '\ec85';
} /* '' */
.icon-unicons-sim-card:before {
  content: '\ec86';
} /* '' */
.icon-unicons-sitemap:before {
  content: '\ec87';
} /* '' */
.icon-unicons-skip-forward:before {
  content: '\ec88';
} /* '' */
.icon-unicons-skip-forward-alt:before {
  content: '\ec89';
} /* '' */
.icon-unicons-skip-forward-circle:before {
  content: '\ec8a';
} /* '' */
.icon-unicons-skype:before {
  content: '\ec8b';
} /* '' */
.icon-unicons-skype-alt:before {
  content: '\ec8c';
} /* '' */
.icon-unicons-slack:before {
  content: '\ec8d';
} /* '' */
.icon-unicons-slack-alt:before {
  content: '\ec8e';
} /* '' */
.icon-unicons-slider-h:before {
  content: '\ec8f';
} /* '' */
.icon-unicons-slider-h-range:before {
  content: '\ec90';
} /* '' */
.icon-unicons-sliders-v:before {
  content: '\ec91';
} /* '' */
.icon-unicons-sliders-v-alt:before {
  content: '\ec92';
} /* '' */
.icon-unicons-smile:before {
  content: '\ec93';
} /* '' */
.icon-unicons-smile-beam:before {
  content: '\ec94';
} /* '' */
.icon-unicons-smile-dizzy:before {
  content: '\ec95';
} /* '' */
.icon-unicons-smile-squint-wink:before {
  content: '\ec96';
} /* '' */
.icon-unicons-smile-squint-wink-alt:before {
  content: '\ec97';
} /* '' */
.icon-unicons-smile-wink:before {
  content: '\ec98';
} /* '' */
.icon-unicons-smile-wink-alt:before {
  content: '\ec99';
} /* '' */
.icon-unicons-snapchat-alt:before {
  content: '\ec9a';
} /* '' */
.icon-unicons-snapchat-ghost:before {
  content: '\ec9b';
} /* '' */
.icon-unicons-snapchat-square:before {
  content: '\ec9c';
} /* '' */
.icon-unicons-snowflake:before {
  content: '\ec9d';
} /* '' */
.icon-unicons-snow-flake:before {
  content: '\ec9e';
} /* '' */
.icon-unicons-snowflake-alt:before {
  content: '\ec9f';
} /* '' */
.icon-unicons-social-distancing:before {
  content: '\eca0';
} /* '' */
.icon-unicons-sort:before {
  content: '\eca1';
} /* '' */
.icon-unicons-sort-amount-down:before {
  content: '\eca2';
} /* '' */
.icon-unicons-sort-amount-up:before {
  content: '\eca3';
} /* '' */
.icon-unicons-sorting:before {
  content: '\eca4';
} /* '' */
.icon-unicons-space-key:before {
  content: '\eca5';
} /* '' */
.icon-unicons-spade:before {
  content: '\eca6';
} /* '' */
.icon-unicons-sperms:before {
  content: '\eca7';
} /* '' */
.icon-unicons-spin:before {
  content: '\eca8';
} /* '' */
.icon-unicons-spinner:before {
  content: '\eca9';
} /* '' */
.icon-unicons-spinner-alt:before {
  content: '\ecaa';
} /* '' */
.icon-unicons-square:before {
  content: '\ecab';
} /* '' */
.icon-unicons-square-full:before {
  content: '\ecac';
} /* '' */
.icon-unicons-square-shape:before {
  content: '\ecad';
} /* '' */
.icon-unicons-squint:before {
  content: '\ecae';
} /* '' */
.icon-unicons-star:before {
  content: '\ecaf';
} /* '' */
.icon-unicons-star-half-alt:before {
  content: '\ecb0';
} /* '' */
.icon-unicons-step-backward:before {
  content: '\ecb1';
} /* '' */
.icon-unicons-step-backward-alt:before {
  content: '\ecb2';
} /* '' */
.icon-unicons-step-backward-circle:before {
  content: '\ecb3';
} /* '' */
.icon-unicons-step-forward:before {
  content: '\ecb4';
} /* '' */
.icon-unicons-stethoscope:before {
  content: '\ecb5';
} /* '' */
.icon-unicons-stethoscope-alt:before {
  content: '\ecb6';
} /* '' */
.icon-unicons-stop-circle:before {
  content: '\ecb7';
} /* '' */
.icon-unicons-stopwatch:before {
  content: '\ecb8';
} /* '' */
.icon-unicons-stopwatch-slash:before {
  content: '\ecb9';
} /* '' */
.icon-unicons-store:before {
  content: '\ecba';
} /* '' */
.icon-unicons-store-alt:before {
  content: '\ecbb';
} /* '' */
.icon-unicons-store-slash:before {
  content: '\ecbc';
} /* '' */
.icon-unicons-streering:before {
  content: '\ecbd';
} /* '' */
.icon-unicons-stretcher:before {
  content: '\ecbe';
} /* '' */
.icon-unicons-subject:before {
  content: '\ecbf';
} /* '' */
.icon-unicons-subway:before {
  content: '\ecc0';
} /* '' */
.icon-unicons-subway-alt:before {
  content: '\ecc1';
} /* '' */
.icon-unicons-suitcase:before {
  content: '\ecc2';
} /* '' */
.icon-unicons-suitcase-alt:before {
  content: '\ecc3';
} /* '' */
.icon-unicons-sun:before {
  content: '\ecc4';
} /* '' */
.icon-unicons-sunset:before {
  content: '\ecc5';
} /* '' */
.icon-unicons-surprise:before {
  content: '\ecc6';
} /* '' */
.icon-unicons-swatchbook:before {
  content: '\ecc7';
} /* '' */
.icon-unicons-swiggy:before {
  content: '\ecc8';
} /* '' */
.icon-unicons-swimmer:before {
  content: '\ecc9';
} /* '' */
.icon-unicons-vertical-distribute-bottom:before {
  content: '\ecca';
} /* '' */
.icon-unicons-vertical-distribution-center:before {
  content: '\eccb';
} /* '' */
.icon-unicons-sync-slash:before {
  content: '\eccc';
} /* '' */
.icon-unicons-syringe:before {
  content: '\eccd';
} /* '' */
.icon-unicons-table:before {
  content: '\ecce';
} /* '' */
.icon-unicons-tablet:before {
  content: '\eccf';
} /* '' */
.icon-unicons-table-tennis:before {
  content: '\ecd0';
} /* '' */
.icon-unicons-tablets:before {
  content: '\ecd1';
} /* '' */
.icon-unicons-tachometer-fast:before {
  content: '\ecd2';
} /* '' */
.icon-unicons-tachometer-fast-alt:before {
  content: '\ecd3';
} /* '' */
.icon-unicons-tag:before {
  content: '\ecd4';
} /* '' */
.icon-unicons-tag-alt:before {
  content: '\ecd5';
} /* '' */
.icon-unicons-tape:before {
  content: '\ecd6';
} /* '' */
.icon-unicons-taxi:before {
  content: '\ecd7';
} /* '' */
.icon-unicons-tear:before {
  content: '\ecd8';
} /* '' */
.icon-unicons-telegram:before {
  content: '\ecd9';
} /* '' */
.icon-unicons-telegram-alt:before {
  content: '\ecda';
} /* '' */
.icon-unicons-telescope:before {
  content: '\ecdb';
} /* '' */
.icon-unicons-temperature:before {
  content: '\ecdc';
} /* '' */
.icon-unicons-temperature-empty:before {
  content: '\ecdd';
} /* '' */
.icon-unicons-temperature-half:before {
  content: '\ecde';
} /* '' */
.icon-unicons-temperature-minus:before {
  content: '\ecdf';
} /* '' */
.icon-unicons-temperature-plus:before {
  content: '\ece0';
} /* '' */
.icon-unicons-temperature-quarter:before {
  content: '\ece1';
} /* '' */
.icon-unicons-temperature-three-quarter:before {
  content: '\ece2';
} /* '' */
.icon-unicons-tennis-ball:before {
  content: '\ece3';
} /* '' */
.icon-unicons-text:before {
  content: '\ece4';
} /* '' */
.icon-unicons-text-fields:before {
  content: '\ece5';
} /* '' */
.icon-unicons-text-size:before {
  content: '\ece6';
} /* '' */
.icon-unicons-text-strike-through:before {
  content: '\ece7';
} /* '' */
.icon-unicons-th:before {
  content: '\ece8';
} /* '' */
.icon-unicons-thermometer:before {
  content: '\ece9';
} /* '' */
.icon-unicons-th-large:before {
  content: '\ecea';
} /* '' */
.icon-unicons-th-slash:before {
  content: '\eceb';
} /* '' */
.icon-unicons-thumbs-down:before {
  content: '\ecec';
} /* '' */
.icon-unicons-thumbs-up:before {
  content: '\eced';
} /* '' */
.icon-unicons-thunderstorm:before {
  content: '\ecee';
} /* '' */
.icon-unicons-thunderstorm-moon:before {
  content: '\ecef';
} /* '' */
.icon-unicons-thunderstorm-sun:before {
  content: '\ecf0';
} /* '' */
.icon-unicons-ticket:before {
  content: '\ecf1';
} /* '' */
.icon-unicons-times:before {
  content: '\ecf2';
} /* '' */
.icon-unicons-times-circle:before {
  content: '\ecf3';
} /* '' */
.icon-unicons-times-square:before {
  content: '\ecf4';
} /* '' */
.icon-unicons-toggle-off:before {
  content: '\ecf5';
} /* '' */
.icon-unicons-toggle-on:before {
  content: '\ecf6';
} /* '' */
.icon-unicons-toilet-paper:before {
  content: '\ecf7';
} /* '' */
.icon-unicons-top-arrow-from-top:before {
  content: '\ecf8';
} /* '' */
.icon-unicons-top-arrow-to-top:before {
  content: '\ecf9';
} /* '' */
.icon-unicons-tornado:before {
  content: '\ecfa';
} /* '' */
.icon-unicons-trademark:before {
  content: '\ecfb';
} /* '' */
.icon-unicons-trademark-circle:before {
  content: '\ecfc';
} /* '' */
.icon-unicons-traffic-barrier:before {
  content: '\ecfd';
} /* '' */
.icon-unicons-traffic-light:before {
  content: '\ecfe';
} /* '' */
.icon-unicons-transaction:before {
  content: '\ecff';
} /* '' */
.icon-unicons-trash:before {
  content: '\ed00';
} /* '' */
.icon-unicons-trash-alt:before {
  content: '\ed01';
} /* '' */
.icon-unicons-trees:before {
  content: '\ed02';
} /* '' */
.icon-unicons-triangle:before {
  content: '\ed03';
} /* '' */
.icon-unicons-trophy:before {
  content: '\ed04';
} /* '' */
.icon-unicons-trowel:before {
  content: '\ed05';
} /* '' */
.icon-unicons-truck:before {
  content: '\ed06';
} /* '' */
.icon-unicons-truck-loading:before {
  content: '\ed07';
} /* '' */
.icon-unicons-tumblr:before {
  content: '\ed08';
} /* '' */
.icon-unicons-tumblr-alt:before {
  content: '\ed09';
} /* '' */
.icon-unicons-tumblr-square:before {
  content: '\ed0a';
} /* '' */
.icon-unicons-tv-retro:before {
  content: '\ed0b';
} /* '' */
.icon-unicons-tv-retro-slash:before {
  content: '\ed0c';
} /* '' */
.icon-unicons-twitter:before {
  content: '\ed0d';
} /* '' */
.icon-unicons-twitter-alt:before {
  content: '\ed0e';
} /* '' */
.icon-unicons-umbrella:before {
  content: '\ed0f';
} /* '' */
.icon-unicons-unamused:before {
  content: '\ed10';
} /* '' */
.icon-unicons-underline:before {
  content: '\ed11';
} /* '' */
.icon-unicons-university:before {
  content: '\ed12';
} /* '' */
.icon-unicons-unlock:before {
  content: '\ed13';
} /* '' */
.icon-unicons-unlock-alt:before {
  content: '\ed14';
} /* '' */
.icon-unicons-upload:before {
  content: '\ed15';
} /* '' */
.icon-unicons-upload-alt:before {
  content: '\ed16';
} /* '' */
.icon-unicons-usd-circle:before {
  content: '\ed17';
} /* '' */
.icon-unicons-usd-square:before {
  content: '\ed18';
} /* '' */
.icon-unicons-user:before {
  content: '\ed19';
} /* '' */
.icon-unicons-user-arrows:before {
  content: '\ed1a';
} /* '' */
.icon-unicons-user-check:before {
  content: '\ed1b';
} /* '' */
.icon-unicons-user-circle:before {
  content: '\ed1c';
} /* '' */
.icon-unicons-user-exclamation:before {
  content: '\ed1d';
} /* '' */
.icon-unicons-user-location:before {
  content: '\ed1e';
} /* '' */
.icon-unicons-user-md:before {
  content: '\ed1f';
} /* '' */
.icon-unicons-user-minus:before {
  content: '\ed20';
} /* '' */
.icon-unicons-user-nurse:before {
  content: '\ed21';
} /* '' */
.icon-unicons-user-plus:before {
  content: '\ed22';
} /* '' */
.icon-unicons-users-alt:before {
  content: '\ed23';
} /* '' */
.icon-unicons-user-square:before {
  content: '\ed24';
} /* '' */
.icon-unicons-user-times:before {
  content: '\ed25';
} /* '' */
.icon-unicons-utensils:before {
  content: '\ed26';
} /* '' */
.icon-unicons-utensils-alt:before {
  content: '\ed27';
} /* '' */
.icon-unicons-vector-square:before {
  content: '\ed28';
} /* '' */
.icon-unicons-vector-square-alt:before {
  content: '\ed29';
} /* '' */
.icon-unicons-venus:before {
  content: '\ed2a';
} /* '' */
.icon-unicons-vertical-align-bottom:before {
  content: '\ed2b';
} /* '' */
.icon-unicons-vertical-align-center:before {
  content: '\ed2c';
} /* '' */
.icon-unicons-vertical-align-top:before {
  content: '\ed2d';
} /* '' */
.icon-unicons-x:before {
  content: '\ed2e';
} /* '' */
.icon-unicons-x-add:before {
  content: '\ed2f';
} /* '' */
.icon-unicons-yen:before {
  content: '\ed30';
} /* '' */
.icon-unicons-yen-circle:before {
  content: '\ed31';
} /* '' */
.icon-unicons-yin-yang:before {
  content: '\ed32';
} /* '' */
.icon-unicons-youtube:before {
  content: '\ed33';
} /* '' */
.icon-unicons-vertical-distribution-top:before {
  content: '\ed34';
} /* '' */
.icon-unicons-video:before {
  content: '\ed35';
} /* '' */
.icon-unicons-video-question:before {
  content: '\ed36';
} /* '' */
.icon-unicons-video-slash:before {
  content: '\ed37';
} /* '' */
.icon-unicons-virus-slash:before {
  content: '\ed38';
} /* '' */
.icon-unicons-visual-studio:before {
  content: '\ed39';
} /* '' */
.icon-unicons-vk:before {
  content: '\ed3a';
} /* '' */
.icon-unicons-vk-alt:before {
  content: '\ed3b';
} /* '' */
.icon-unicons-voicemail:before {
  content: '\ed3c';
} /* '' */
.icon-unicons-voicemail-rectangle:before {
  content: '\ed3d';
} /* '' */
.icon-unicons-volleyball:before {
  content: '\ed3e';
} /* '' */
.icon-unicons-volume:before {
  content: '\ed3f';
} /* '' */
.icon-unicons-volume-down:before {
  content: '\ed40';
} /* '' */
.icon-unicons-volume-mute:before {
  content: '\ed41';
} /* '' */
.icon-unicons-volume-off:before {
  content: '\ed42';
} /* '' */
.icon-unicons-volume-up:before {
  content: '\ed43';
} /* '' */
.icon-unicons-vuejs:before {
  content: '\ed44';
} /* '' */
.icon-unicons-vuejs-alt:before {
  content: '\ed45';
} /* '' */
.icon-unicons-wall:before {
  content: '\ed46';
} /* '' */
.icon-unicons-wallet:before {
  content: '\ed47';
} /* '' */
.icon-unicons-watch:before {
  content: '\ed48';
} /* '' */
.icon-unicons-watch-alt:before {
  content: '\ed49';
} /* '' */
.icon-unicons-water:before {
  content: '\ed4a';
} /* '' */
.icon-unicons-water-drop-slash:before {
  content: '\ed4b';
} /* '' */
.icon-unicons-water-glass:before {
  content: '\ed4c';
} /* '' */
.icon-unicons-webcam:before {
  content: '\ed4d';
} /* '' */
.icon-unicons-web-grid:before {
  content: '\ed4e';
} /* '' */
.icon-unicons-web-grid-alt:before {
  content: '\ed4f';
} /* '' */
.icon-unicons-web-section:before {
  content: '\ed50';
} /* '' */
.icon-unicons-web-section-alt:before {
  content: '\ed51';
} /* '' */
.icon-unicons-weight:before {
  content: '\ed52';
} /* '' */
.icon-unicons-whatsapp:before {
  content: '\ed53';
} /* '' */
.icon-unicons-whatsapp-alt:before {
  content: '\ed54';
} /* '' */
.icon-unicons-wheel-barrow:before {
  content: '\ed55';
} /* '' */
.icon-unicons-wheelchair:before {
  content: '\ed56';
} /* '' */
.icon-unicons-wheelchair-alt:before {
  content: '\ed57';
} /* '' */
.icon-unicons-wifi:before {
  content: '\ed58';
} /* '' */
.icon-unicons-wifi-router:before {
  content: '\ed59';
} /* '' */
.icon-unicons-wifi-slash:before {
  content: '\ed5a';
} /* '' */
.icon-unicons-wind:before {
  content: '\ed5b';
} /* '' */
.icon-unicons-wind-moon:before {
  content: '\ed5c';
} /* '' */
.icon-unicons-window:before {
  content: '\ed5d';
} /* '' */
.icon-unicons-window-grid:before {
  content: '\ed5e';
} /* '' */
.icon-unicons-window-maximize:before {
  content: '\ed5f';
} /* '' */
.icon-unicons-windows:before {
  content: '\ed60';
} /* '' */
.icon-unicons-window-section:before {
  content: '\ed61';
} /* '' */
.icon-unicons-windsock:before {
  content: '\ed62';
} /* '' */
.icon-unicons-wind-sun:before {
  content: '\ed63';
} /* '' */
.icon-unicons-windy:before {
  content: '\ed64';
} /* '' */
.icon-unicons-wordpress:before {
  content: '\ed65';
} /* '' */
.icon-unicons-wordpress-simple:before {
  content: '\ed66';
} /* '' */
.icon-unicons-wrap-text:before {
  content: '\ed67';
} /* '' */
.icon-unicons-wrench:before {
  content: '\ed68';
} /* '' */
